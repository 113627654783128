<script setup>
import { useDatacenterStore } from "@/stores/DataCenterStore";

const dataCenterStore = useDatacenterStore();
</script>
<template>
  <div class="mdl-grid">
    <div class="mdl-cell--12-col mdl-cell--12-col-phone subtitle subtitle">
      {{ $t("crossConnects.orderDetails") }}
    </div>
  </div>
  <div class="mdl-grid">
    <div
      v-if="dataCenterStore.purchase_order"
      class="mdl-cell--4-col mdl-cell--4-col-phone info-item"
    >
      <span class="label"
        >{{ $t("crossConnects.order") }}
        <span class="value">{{ dataCenterStore.purchase_order }}</span></span
      >
    </div>
    <div class="mdl-cell--12-col mdl-cell--12-col-phone info-item">
      <span class="label"
        >{{ $t("crossConnects.notes") }}
        <span class="value">
          {{ dataCenterStore.description }}
        </span></span
      >
    </div>
  </div>

  <div class="mdl-grid">
    <div class="mdl-cell--12-col mdl-cell--12-col-phone subtitle">
      <span>{{ $t("crossConnects.crossConnectReferral") }}</span>
    </div>
  </div>
  <div class="mdl-grid" style="align-items: baseline">
    <!-- XCONN REF NAME -->
    <div class="mdl-cell--2-col mdl-cell--12-col-phone info-item">
      <span class="label">{{ $t("crossConnects.fullName") }}</span>
    </div>
    <div class="mdl-cell--4-col mdl-cell--12-col-phone info-item">
      <span class="value"
        >{{ dataCenterStore.ref_name }} {{ dataCenterStore.ref_surname }}</span
      >
    </div>

    <!-- XCONN REF PHONE -->
    <div class="mdl-cell--1-col mdl-cell--12-col-phone">
      <span class="label">{{ $t("refTicket.phoneRef") }} </span>
    </div>
    <div class="mdl-cell--2-col mdl-cell--12-col-phone info-item">
      <span class="value">{{ dataCenterStore.ref_phone }}</span>
    </div>

    <!-- XCONN REF EMAIL -->
    <div class="mdl-cell--1-col mdl-cell--12-col-phone">
      <span class="label">{{ $t("refTicket.emailRef") }}</span>
    </div>
    <div class="mdl-cell--2-col mdl-cell--12-col-phone info-item">
      <span class="value">{{ dataCenterStore.ref_email }}</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.subtitle {
  color: #8895a7;
}
.info-row {
  display: flex;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 3rem;
}
.label {
  font-family: MontserratMedium;
  .value {
    font-family: OpenSansBold;
    font-size: 22px;
  }
}

.info-item {
  margin: 5px 0px 10px 0px;
}
</style>
