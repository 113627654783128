<template>
  <button id="btnModal" :class="props.btnClass" @click="props.clickHandler" v-if="props.condition" style="width: 100%;">
    {{ $t(props.labelKey) }}
  </button>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps(['btnClass', 'clickHandler', 'condition', 'labelKey']);
</script>
