<template>
  <div className="card">
    <div className="label-container">
      <label htmlFor="E100" className="cardTitle" style="margin-top: 12px"
        >B - END</label
      >
    </div>
    <div className="divider"></div>
    <div v-if="props.kindOfKit === 'is_my_muffola'">
      <MuffolaOwnership
        :buildings="buildings"
        :isRedundant="redundant"
        :wings="wings"
        :is-validation-initiated="validationInitiated"
        @update-building="setSelectedBuilding"
        @update-wing="setSelectedWing"
        @toggle-changed="handleToggleChangedInsideCard"
      />
    </div>
    <div v-if="props.kindOfKit">
      <LoaUpload :isRedundant="redundant" />
    </div>
    <div v-if="!props.kindOfKit">
      <LoaInformation />
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, onMounted } from "vue";
import LoaInformation from "@/components/CrossConnect/CrossFormComponents/BEndInfraScenarioComponents/LoaInformation";
import LoaUpload from "@/components/CrossConnect/CrossFormComponents/BEndInfraScenarioComponents/LoaUpload";
import MuffolaOwnership from "@/components/CrossConnect/CrossFormComponents/BEndInfraScenarioComponents/MuffolaOwnership";
import { useDatacenterStore } from "@/stores/DataCenterStore";

const datacenterStore = useDatacenterStore();

const buildings = computed(() => {
  return datacenterStore.buildings;
});
const wings = computed(() => {
  return datacenterStore.wings;
});

const props = defineProps({
  kindOfKit: String,
  validationInitiated: Boolean,
  redundant: Boolean,
});

const emits = defineEmits(["update-building", "update-wing", "toggle-changed"]);

const setSelectedBuilding = (event) => {
  if (props.redundant) {
    datacenterStore.rBuildingName = event.target.selectedOptions[0].label;
    datacenterStore.rBuilding = event.target.value;
  } else {
    datacenterStore.buildingName = event.target.selectedOptions[0].label;
    datacenterStore.building = event.target.value;
  }
};

const setSelectedWing = (event) => {
  if (props.redundant) {
    datacenterStore.rWingName = event.target.selectedOptions[0].label;
    datacenterStore.rWing = event.target.value;
  } else {
    datacenterStore.wingName = event.target.selectedOptions[0].label;
    datacenterStore.wing = event.target.value;
  }
};

const handleToggleChangedInsideCard = (value) => {
  emits("toggle-changed", value);
};

onMounted(async () => {
  try {
    // Await the completion of fetchDatacenterInfo and fetchKitInfo
    await Promise.all([
      datacenterStore.fetchBuildings(),
      datacenterStore.fetchWings(),
    ]);
  } catch (e) {
    console.error("Error during fetch operations:", e);
    // Handle errors if needed
  }
});
</script>
<style>
/* Add your styles here */
</style>
