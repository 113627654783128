<template>
  <div class="cdlSideMenu">
    <div
      class="contentcdlSideMenu"
      style="
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      "
    >
      <div class="head" @mouseover="onOpenMenu(99)">
        <img :src="MyLogo" alt="CDLAN S.p.A." width="70" height="70" />
      </div>

      <div class="listMenuPrimary listMenuMaster">
        <router-link
          to="/"
          class="section-link cdlMenu cdlMenu-99 cdlMenuHome"
          id="homePageMenu"
          @mouseover="onOpenMenu(99)"
          @click="setNavigator('', 99)"
        >
          <span class="material-icons">dashboard</span>
          <span class="elmMenu">Dashboard</span>
        </router-link>

        <div v-for="(item, key) in menu" :key="key">
          <div
            class="section-link cdlMenu"
            :class="['cdlMenu-' + key]"
            @mouseover="onOpenMenu(key)"
            :id="'menu-' + key"
            v-if="shouldDisplayMenuItem(item, key)"
          >
            <span class="material-icons" :style="item.style ?? {}">{{
              item.icon
            }}</span>
            <span class="elmMenu">{{ $t(item.label) }}</span>
          </div>
        </div>
      </div>
      <div class="listMenuPrimary" style="flex-grow: 1; position: relative">
        <div class="privacyPolicy">
          <span class="elmMenu" style="margin: 20px 0">
            <a :href="this.linkToPrivacyPolicy" target="_blank"
              >Privacy Policy</a
            >
          </span>
        </div>
      </div>
    </div>

    <div
      class="contentcdlSideMenu andMore"
      :class="{ active: openMenu, close: closeMenu }"
    >
      <div class="listMenuSecondary">
        <div v-for="(item, key) in menu" :key="key">
          <div
            class="section-link subMenu"
            :class="['andMore-' + key]"
            v-if="item.child && shouldDisplayMenuItem(item)"
          >
            <div class="groupMenu">
              <div
                v-for="(levelOne, kOne) in filteredLevelOneItems(item.child)"
                :key="kOne"
              >
                <div v-if="levelOne.child">
                  <div
                    class="titleGroupMenu"
                    :class="[
                      'subMenu-' + key + '-' + kOne,
                      { active: levelOne.open },
                    ]"
                    @click="ctlMenuOne(key, kOne)"
                  >
                    <span>{{ $t(levelOne.label) }}</span>
                    <span
                      class="material-icons"
                      :class="[
                        'icon-' + key + '-' + kOne,
                        { active: levelOne.open },
                      ]"
                      >keyboard_arrow_down</span
                    >
                  </div>
                  <div
                    class="listMenu"
                    :class="[
                      'listMenu-' + key + '-' + kOne,
                      { active: levelOne.open },
                    ]"
                    style="margin-top: 20px"
                  >
                    <div
                      v-for="(levelTwo, kTwo) in filteredLevelTwoItems(
                        levelOne.child
                      )"
                      :key="kTwo"
                    >
                      <div v-if="levelTwo.child">
                        <div
                          class="titleGroupMenu"
                          :class="['subMenu-' + key + '-' + kOne + '-' + kTwo]"
                          @click="ctlMenuTwo(key, kOne, kTwo)"
                          v-if="shouldDisplayMenuItem(levelTwo)"
                        >
                          <span>{{ $t(levelTwo.label) }}</span>
                          <span
                            class="material-icons"
                            :class="[
                              'icon-' + key + '-' + kOne + '-' + kTwo,
                              { active: !levelTwo.open },
                            ]"
                          >
                            keyboard_arrow_down
                          </span>
                        </div>
                        <div
                          v-for="(levelThree, kThree) in levelTwo.child"
                          :key="kThree"
                        >
                          <div
                            v-if="levelThree.child"
                            class="listMenu"
                            :class="[{ active: levelThree.open }]"
                            style="margin-top: 20px"
                          >
                            <div
                              class="titleGroupMenu"
                              :class="[
                                'subMenu-' +
                                  key +
                                  '-' +
                                  kOne +
                                  '-' +
                                  kTwo +
                                  '-' +
                                  kThree,
                              ]"
                              @click="ctlMenuThree(key, kOne, kTwo, kThree)"
                              v-if="shouldDisplayMenuItem(levelThree)"
                            >
                              <span>{{ $t(levelThree.label) }}</span>
                              <span
                                class="material-icons"
                                :class="[
                                  'icon-' +
                                    key +
                                    '-' +
                                    kOne +
                                    '-' +
                                    kTwo +
                                    '-' +
                                    kThree,
                                  { active: !levelThree.open },
                                ]"
                              >
                                keyboard_arrow_down
                              </span>
                            </div>
                            <div
                              v-for="(levelFour, kFour) in levelThree.child"
                              :key="kFour"
                            >
                              <div
                                class="listMenu"
                                :class="[
                                  'listMenu-' +
                                    key +
                                    '-' +
                                    kOne +
                                    '-' +
                                    kTwo +
                                    '-' +
                                    kThree +
                                    '-' +
                                    kFour,
                                  { active: levelFour.open },
                                ]"
                                style="margin-top: 20px"
                              >
                                <div
                                  class="listMenu active marginLeft0"
                                  v-if="shouldDisplayMenuItem(levelFour)"
                                >
                                  <router-link
                                    :to="'/' + $t(levelFour.url)"
                                    @click="
                                      setNavigator(
                                        $t(levelFour.label),
                                        key,
                                        kOne,
                                        kTwo,
                                        kThree,
                                        kFour
                                      )
                                    "
                                    :id="'menu-' + $t(levelFour.url)"
                                  >
                                    {{ $t(levelFour.label) }}
                                  </router-link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-else
                            class="listMenu"
                            :class="[
                              'listMenu-' +
                                key +
                                '-' +
                                kOne +
                                '-' +
                                kTwo +
                                '-' +
                                kThree,
                              { active: levelThree.open },
                            ]"
                            style="margin-top: 20px"
                          >
                            <div
                              class="listMenu active marginLeft0"
                              v-if="
                                shouldDisplayMenuItem(
                                  levelThree,
                                  'verifica_copertura',
                                  'nav.checkCoverageUrl'
                                )
                              "
                            >
                              <router-link
                                :to="'/' + $t(levelThree.url)"
                                @click="
                                  setNavigator(
                                    $t(levelThree.label),
                                    key,
                                    kOne,
                                    kTwo,
                                    kThree
                                  )
                                "
                                :id="'menu-' + $t(levelThree.url)"
                              >
                                {{ $t(levelThree.label) }}
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="listMenu active marginLeft0"
                        v-else-if="shouldDisplayMenuItem(levelTwo)"
                      >
                        <router-link
                          :to="'/' + $t(levelTwo.url)"
                          @click="
                            setNavigator($t(levelTwo.label), key, kOne, kTwo)
                          "
                          :id="'menu-' + $t(levelTwo.url)"
                        >
                          {{ $t(levelTwo.label) }}
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="listMenu active marginLeft0" v-else>
                  <router-link
                    :to="'/' + $t(levelOne.url)"
                    :id="'menu-' + $t(levelOne.url)"
                    @click="setNavigator($t(levelOne.label), key, kOne)"
                  >
                    {{ $t(levelOne.label) }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.privacyPolicy {
  position: absolute;
  bottom: 20px;
  font-size: 16px;
  a {
    color: #8895a7;
    &:hover {
      background: none;
      text-decoration: underline !important;
      color: #f8f9fa;
    }
  }
}
.shepherd-modal-overlay-container {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.shepherd-modal-is-visible {
  opacity: 0.2 !important;
}

/* Prevent the overlay from being affected by body zoom */
.shepherd-modal-overlay-container *,
.shepherd-modal-overlay-container *::before,
.shepherd-modal-overlay-container *::after {
  transform: none !important;
}
/* Ensure steps are positioned relative to the viewport */
.shepherd-step {
  position: fixed !important;
}

/* Reset transforms on the step elements */
.shepherd-step *,
.shepherd-step *::before,
.shepherd-step *::after {
  transform: none !important;
}
.custom-shepherd-popup {
  background: white; // Light teal background color to match the example
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  width: 600px; // Increased max-width from 300px to 600px
  font-family: "MontserratMedium";
  color: #333; // Dark text color for readability
  position: relative;
  &.popup-language {
    &:after {
      display: none;
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: -15px; // Position to create a speech bubble arrow
    margin-top: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent #e0f4f1 transparent transparent;
  }
}

.custom-next-button,
.custom-skip-button {
  background-image: linear-gradient(6deg, #011930 0%, #032f5e 100%);
  color: white;
  border-radius: 41px;
  padding: 10px 15px; // Reduced padding to make buttons smaller
  font-family: "MontserratMedium";
  border: 0;
  cursor: pointer;
  font-size: 1rem; // Reduced font size
  opacity: 0.8;
  margin: 0 5px; // Removed width and centering, added margin between buttons
  &:hover {
    background: #0056b3; // Darker blue on hover
  }
}

.custom-skip-button {
  background: #6c757d; // Gray color for "Skip" button
  &:hover {
    background: #5a6268; // Darker gray on hover
  }
}
.custom-language-button {
  background-image: linear-gradient(6deg, #011930 0%, #032f5e 100%);
  color: white;
  border-radius: 41px;
  padding: 10px 20px; /* Adjust padding as needed */
  font-family: "MontserratMedium";
  border: 0;
  cursor: pointer;
  font-size: 1rem;
  opacity: 0.8;
  margin: 10px 5px; /* Adjust margin to space out the buttons */
  display: flex;
  align-items: center;
  text-decoration: none;
}

.custom-language-button:hover {
  background: #0056b3;
}

.custom-language-button img {
  width: 24px; /* Adjust the flag width */
  height: 24px; /* Adjust the flag height */
  margin-right: 8px; /* Space between flag and text */
}

.language-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
</style>

<script>
import "@/assets/scss/Menu.scss";
import MyLogo from "@/assets/img/Pittogramma.svg";
import menuJson from "@/configs/menu.json";
import { useShepherd } from "vue-shepherd";
import "shepherd.js/dist/css/shepherd.css";

import Cookies from "js-cookie";
import i18n from "@/i18n";
import EnglishFlag from "@/assets/img/englishflag.png";
import ItalianFlag from "@/assets/img/italianflag.png";
import { useUserStore } from "@/stores/UserStore";

export default {
  name: "MenuDesktop",
  props: {
    ctrlMenuDesktop: {
      type: Boolean,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      MyLogo: MyLogo,
      openMenu: false,
      closeMenu: true,
      menu: menuJson,
      userLanguage: "",
      tour: null,
    };
  },
  methods: {
    handleLanguageSelection(lang) {
      this.changLang(lang);
      this.tour.next();
    },
    filteredLevelOneItems(children) {
      return children.filter((child) => this.shouldDisplayMenuItem(child));
    },
    filteredLevelTwoItems(children) {
      return children.filter((child) => this.shouldDisplayMenuItem(child));
    },
    shouldDisplayMenuItem(item) {
      // Check if the item is 'Check Coverage' by matching its URL or label
      if (
        item.url === "nav.checkCoverageUrl" ||
        item.label === "nav.checkCoverageLabel"
      ) {
        return this.getPermissionForDirectUser(item, "verifica_copertura");
      }
      // For 'Area Partner' menu item
      if (item.permissions && item.permissions.includes("area_partner")) {
        return this.getPermissionForDirectUser(item, "area_partner");
      }
      // Display all other items
      return true;
    },
    changLang(value) {
      // Update language state immediately
      i18n.global.locale.value = value;
      i18n.global.fallbackLocale.value = value;

      document.querySelector("html").setAttribute("lang", value);

      // Optionally update user preference on the server asynchronously
      const url =
        this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
        "base/v1/assenzio/preferences";
      const headers = {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      };

      this.axios
        .put(url, { language: value }, { headers })
        .then(() => {
          useUserStore().user.preferences.language = value;
        })
        .catch((error) => {
          console.error("Error updating language:", error);
        });
    },
    hasTourBeenSeen() {
      return !!Cookies.get("tourSeen");
    },
    setNavigator(path, index, indexSub) {
      this.mylastUrl = path;
      if (this.mylastUrl == "") {
        let list = { mylastUrl: path };
        localStorage.setItem("cdlSetups", JSON.stringify(list));
      }
      let elms = document.getElementsByClassName("cdlMenu");
      Object.entries(elms).forEach((elm) => {
        elm[1].classList.remove("active");
      });
      if (index != 99) {
        document
          .getElementsByClassName("cdlMenuHome")[0]
          .classList.remove("router-link-active");
        document
          .getElementsByClassName("cdlMenu-" + index)[0]
          .classList.add("active");
        if (indexSub != 0) {
          if (document.getElementsByClassName("subMenu-" + indexSub)[0]) {
            document
              .getElementsByClassName("subMenu-" + indexSub)[0]
              .classList.add("active");
          }
        }
      }
      this.onOpenMenu(99);
    },
    ctrlPerms() {
      return true;
    },
    onOpenMenu(ctrl) {
      if (ctrl == 99) {
        this.openMenu = false;
        this.closeMenu = true;
        return;
      } else {
        this.openMenu = true;
        this.closeMenu = false;
      }
      let elms = document.getElementsByClassName("subMenu");
      Object.entries(elms).forEach((elm) => {
        elm[1].classList.remove("active");
      });
      if (document.getElementsByClassName("andMore-" + ctrl)[0]) {
        document
          .getElementsByClassName("andMore-" + ctrl)[0]
          .classList.add("active");
      }
    },
    startTour() {
      if (!Cookies.get("tourSeen")) {
        setTimeout(() => {
          this.tour.start();
        }, 1500);
      }
    },
    ctlMenuOne(key, kOne) {
      let obj = this.menu[key].child[kOne];
      obj.child.forEach((elm, i) => {
        this.menu[key].child[kOne].child[i].open =
          !this.menu[key].child[kOne].child[i].open;
      });
      this.menu[key].child[kOne].open = !this.menu[key].child[kOne].open;
    },
    ctlMenuTwo(key, kOne, kTwo) {
      let obj = this.menu[key].child[kOne].child[kTwo];
      obj.child.forEach((elm, i) => {
        this.menu[key].child[kOne].child[kTwo].child[i].open =
          !this.menu[key].child[kOne].child[kTwo].child[i].open;
      });
      this.menu[key].child[kOne].child[kTwo].open =
        !this.menu[key].child[kOne].child[kTwo].open;
    },
    ctlMenuThree(key, kOne, kTwo, kThree) {
      let obj = this.menu[key].child[kOne].child[kTwo].child[kThree];
      obj.child.forEach((elm, i) => {
        this.menu[key].child[kOne].child[kTwo].child[kThree].child[i].open =
          !this.menu[key].child[kOne].child[kTwo].child[kThree].child[i].open;
      });
      this.menu[key].child[kOne].child[kTwo].open =
        !this.menu[key].child[kOne].child[kTwo].open;
    },
    getPermissionForDirectUser(level, permission, url = null) {
      let isPermissionCoverage = !!level.permissions.find(
        (element) => element == permission
      );
      let isPageCoverage = url ? level.url == url : true;
      if (!this.user.is_partner) {
        if (isPermissionCoverage && isPageCoverage) {
          return false;
        }
      }
      return this.ctrlPerms(level.permissions);
      // return true;
    },
    setTourSeen() {
      document.cookie =
        "tourSeen=true; max-age=" + 60 * 60 * 24 * 30 + "; path=/";
    },
  },
  watch: {
    ctrlMenuDesktop: {
      handler() {
        this.openMenu = false;
        this.closeMenu = true;
      },
      deep: true,
      immediate: true,
    },
    lang: {
      handler(newValue) {
        this.userLanguage = newValue;
      },
    },
  },
  computed: {
    linkToPrivacyPolicy() {
      const IT_LANG = "it";
      return this.$props.lang === IT_LANG
        ? "https://www.cdlan.it/privacy-cookies"
        : "https://www.cdlan.it/en/privacy-cookies";
    },
  },
  mounted() {
    this.tour = useShepherd({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: "custom-shepherd-popup",
        scrollTo: true,
        modal: false,
        arrow: true, // Add arrow to the popups for context
        popperOptions: {
          strategy: "fixed", // Use fixed positioning
          modifiers: [{ name: "offset", options: { offset: [0, 12] } }],
        },
      },
    });

    // // Add Shepherd steps for each menu item
    // this.tour.addStep({
    //   id: "dashboard-step",
    //   attachTo: { element: "#homePageMenu", on: "right" },
    //   text: "This is the Dashboard menu item. Click here to go to the dashboard.",
    //   classes: "custom-shepherd-popup", // Custom class for the desired styling
    //   buttons: [
    //     {
    //       text: "Next",
    //       action: this.tour.next,
    //       classes: 'custom-next-button', // Custom button style
    //     },
    //   ],
    // });
    // Add the language selection step
    // Add the language selection step
    this.tour.addStep({
      id: "language-selection-step",
      text: `
      <div class="language-buttons">
        <button id="lang-en" class="custom-language-button">
          <img src="${EnglishFlag}" alt="English Flag" /> English
        </button>
        <button id="lang-it" class="custom-language-button">
          <img src="${ItalianFlag}" alt="Italian Flag" /> Italiano
        </button>
      </div>
    `,
      classes: "custom-shepherd-popup popup-language",
      buttons: [],
      when: {
        show: () => {
          // Define and store event handlers
          this.langEnClickHandler = () => {
            this.handleLanguageSelection("en");
          };
          this.langItClickHandler = () => {
            this.handleLanguageSelection("it");
          };

          // Add event listeners
          document
            .getElementById("lang-en")
            .addEventListener("click", this.langEnClickHandler);
          document
            .getElementById("lang-it")
            .addEventListener("click", this.langItClickHandler);
        },
        hide: () => {
          // Remove event listeners
          document
            .getElementById("lang-en")
            .removeEventListener("click", this.langEnClickHandler);
          document
            .getElementById("lang-it")
            .removeEventListener("click", this.langItClickHandler);
        },
      },
    });

    this.tour.addStep({
      id: "solutions-step",
      attachTo: { element: "#menu-0", on: "right" },
      text: () => this.$t("tour.solutions"),
      classes: "custom-shepherd-popup",
      buttons: [
        {
          text: () => this.$t("buttons.next"),
          action: this.tour.next,
          when: {
            show: () => {
              return new Promise((resolve) => {
                const checkExist = setInterval(() => {
                  if (document.querySelector("#menu-0")) {
                    clearInterval(checkExist);
                    resolve();
                  }
                }, 100);
              });
            },
          },
          classes: "custom-next-button",
        },
        {
          text: () => this.$t("buttons.skip"),
          action: () => {
            this.setTourSeen();
            this.tour.complete();
          },
          classes: "custom-skip-button",
        },
      ],
    });

    this.tour.addStep({
      id: "services-step",
      attachTo: { element: "#menu-1", on: "right" },
      text: () => this.$t("tour.services"),
      classes: "custom-shepherd-popup",
      buttons: [
        {
          text: () => this.$t("buttons.next"),
          action: this.tour.next,
          classes: "custom-next-button",
        },
        {
          text: () => this.$t("buttons.skip"),
          action: () => {
            this.setTourSeen();
            this.tour.complete();
          },
          classes: "custom-skip-button",
        },
      ],
    });

    this.tour.addStep({
      id: "management-step",
      attachTo: { element: "#menu-2", on: "right" },
      text: () => this.$t("tour.management"),
      classes: "custom-shepherd-popup",
      buttons: [
        {
          text: () => this.$t("buttons.next"),
          action: this.tour.next,
          classes: "custom-next-button",
        },
        {
          text: () => this.$t("buttons.skip"),
          action: () => {
            this.setTourSeen();
            this.tour.complete();
          },
          classes: "custom-skip-button",
        },
      ],
    });

    this.tour.addStep({
      id: "report-step",
      attachTo: { element: "#menu-3", on: "right" },
      text: () => this.$t("tour.report"),
      classes: "custom-shepherd-popup",
      buttons: [
        {
          text: () => this.$t("buttons.next"),
          action: this.tour.next,
          classes: "custom-next-button",
        },
        {
          text: () => this.$t("buttons.skip"),
          action: () => {
            this.setTourSeen();
            this.tour.complete();
          },
          classes: "custom-skip-button",
        },
      ],
    });

    this.tour.addStep({
      id: "settings-step",
      attachTo: { element: "#menu-4", on: "right" },
      text: () => this.$t("tour.settings"),
      classes: "custom-shepherd-popup",
      buttons: [
        {
          text: () => this.$t("buttons.next"),
          action: this.tour.next,
          classes: "custom-next-button",
        },
        {
          text: () => this.$t("buttons.skip"),
          action: () => {
            this.setTourSeen();
            this.tour.complete();
          },
          classes: "custom-skip-button",
        },
      ],
    });

    this.tour.addStep({
      id: "partner-area-step",
      attachTo: { element: "#menu-5", on: "right" },
      text: () => this.$t("tour.partner_area"),
      classes: "custom-shepherd-popup",
      buttons: [
        {
          text: () => this.$t("buttons.next"),
          action: this.tour.next,
          classes: "custom-next-button",
        },
        {
          text: () => this.$t("buttons.skip"),
          action: () => {
            this.setTourSeen();
            this.tour.complete();
          },
          classes: "custom-skip-button",
        },
      ],
    });

    // Set the cookie when the tour is completed
    this.tour.on("complete", () => {
      this.setTourSeen();
    });

    this.$nextTick(() => {
      if (!this.hasTourBeenSeen()) {
        this.startTour();

        // Adjust Shepherd.js elements after the tour starts
        setTimeout(() => {
          const bodyRect = document.body.getBoundingClientRect();
          const windowWidth = window.innerWidth;
          const zoomFactor = bodyRect.width / windowWidth || 1; // Default to 1 if division by zero

          // Apply inverse scaling
          const shepherdScale = 1 / zoomFactor;

          // Apply the scale to the Shepherd.js elements
          const shepherdElements = document.querySelectorAll(
            ".shepherd-modal, .shepherd-element, .shepherd-step"
          );
          shepherdElements.forEach((element) => {
            element.style.transform = `scale(${shepherdScale})`;
            element.style.transformOrigin = "top left";
          });
        }, 500); // Delay to ensure elements are rendered
      }
    });
  },
};
</script>
