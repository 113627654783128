<template>
  <div id="Cart" v-if="viewLoader">
    <MyBanner :text="$t('banner.cartPage')" />
    <MyHeaderPage :title="$t('cart.title')" :subtitle="$t('cart.subTitle')" />
    <br v-if="carts.length > 0" />
    <div class="contentItems" v-if="carts.length > 0">
      <div class="headerOrder">
        <div>
          <p class="material-icons receipt_long">receipt_long</p>
        </div>
        <div>
          <p class="orderName">{{ $t("cart.orderName") }}</p>
        </div>
        <!-- INIZIO TEST -->
        <div id="TicketsList">
          <div
            class="listTicket"
            style="box-shadow: unset; background-color: transparent"
          >
            <div class="contentInputFilter" style="margin-bottom: 0">
              <div class="multiselectFilter">{{ $t("cart.newRequest") }}</div>
              <div
                class="contentIcon cursorPointer"
                @click="openMultiselect('period')"
              >
                <span class="material-icons" v-if="multiSelected.period.onOff"
                  >expand_less</span
                >
                <span class="material-icons" v-if="!multiSelected.period.onOff"
                  >expand_more</span
                >
              </div>
            </div>

            <div class="listMultiselect" v-if="multiSelected.period.onOff">
              <div
                @click="setPage($t('nav.checkCoverageUrl'))"
                style="cursor: pointer"
              >
                {{ $t("nav.checkCoverage") }}
              </div>
              <div @click="setPage('cross-connects')" style="cursor: pointer">
                Cross Connects
              </div>
            </div>
          </div>
        </div>
        <!-- FINE TEST -->
        <div class="contentButtonNewOrder" v-if="false">
          <span
            class="material-icons CdlIcon"
            style="font-size: 3rem"
            @click="newOrder()"
            v-tooltip:left.tooltip="$t('cart.addOrder')"
            >add</span
          >
        </div>
      </div>

      <div
        :class="[
          'itemCart',
          'itemCartFirst',
          cart.category === 'ACCESSO'
            ? getClassKit(cart.network_access_details.technology)
            : 'orange',
          key > 0 ? 'marginTop' : '',
        ]"
        v-for="(cart, key) in carts"
        :key="key"
      >
        <div
          v-if="cart.category === 'ACCESSO'"
          :class="getClassKit(cart.network_access_details.technology)"
        >
          <div class="mdl-grid">
            <div class="mdl-cell mdl-cell--12-col expireDate">
              <span
                >{{ $t("cart.expireDate") }} {{ getExpired(cart.expire_date) }}
                {{ $t("cart.expireDateTime") }}
                {{ getExpiredTime(cart.expire_date) }}</span
              >
            </div>
            <div class="mdl-cell mdl-cell--10-col expireDate">
              <h3>{{ $t("cart.forniture") }}</h3>
            </div>

            <div
              class="mdl-cell mdl-cell--2-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
            >
              <div class="contents-ctrl" style="text-align: center">
                <span
                  :id="['item-cart-' + key]"
                  class="material-icons CdlIcon CdlIconReverse"
                  @click="getItem(cart)"
                  v-tooltip:bottom.tooltip="$t('cart.edit')"
                  >edit</span
                >
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span
                  class="material-icons CdlIcon CdlIconReverse"
                  @click="modalRemoveItem(cart.id)"
                  v-tooltip:bottom.tooltip="$t('cart.deleteSolution')"
                  >delete</span
                >
              </div>
            </div>

            <div class="mdl-cell mdl-cell--12 content-elms">
              <div class="contents-icons">
                <img
                  :src="getIcon(cart.network_access_details.technology)"
                  alt="technology"
                  width="30"
                />
                <br /><br />
                <img
                  src="@/assets/img/icon_pin.png"
                  alt="location"
                  width="30"
                />
              </div>
              <div class="contents-info">
                <span class="value positionLabel"
                  ><span class="label">{{
                    $t("checkCoverageSummary.supplyActivation")
                  }}</span>
                  {{ cart.title }}</span
                >
                <br /><br /><br />
                <span class="value positionLabel"
                  ><span class="label">{{
                    $t("checkCoverage.detailLabelAddress")
                  }}</span>
                  {{ getAddress(cart.network_access_details.address) }}</span
                >
              </div>
              <div class="contents-more-info" v-if="!IsMobile">
                <span class="moreInfo" @click="viewDetails(cart.id)"
                  >{{ $t("cart.details") }}
                  <span
                    class="material-icons"
                    :id="'iconDetails-' + key"
                    :class="'iconDetails-' + cart.id"
                    >expand_more</span
                  >
                </span>
              </div>
            </div>
            <div class="mdl-cell mdl-cell--12" v-if="IsMobile">
              <div class="contents-more-info mobile">
                <span class="moreInfo" @click="viewDetails(cart.id)">
                  <span>{{ $t("cart.details") }}</span>
                  <span
                    class="material-icons"
                    :id="'iconDetails-' + key"
                    :class="'iconDetails-' + cart.id"
                    >expand_more</span
                  >
                </span>
              </div>
            </div>

            <div
              class="mdl-cell mdl-cell--10-col viewDetails"
              :class="'viewDetails-' + cart.id"
            >
              <hr />
            </div>

            <div
              class="mdl-cell mdl-cell--12-col content-elms contentElmsDetails"
              :class="'viewDetails-' + cart.id"
            >
              <div
                class="viewDetails viewDetails-0-1"
                :class="'viewDetails-' + cart.id"
              >
                <div class="contents-icons">
                  <div v-if="cart.cabinet_distance">
                    <img
                      src="@/assets/img/icon_kit_three.png"
                      alt="icon"
                      width="30"
                    />
                    <br /><br />
                  </div>
                  <img
                    src="@/assets/img/icon_kit_five.png"
                    alt="icon"
                    width="30"
                  />
                  <br /><br />
                  <img
                    src="@/assets/img/icon_kit_four.png"
                    alt="icon"
                    width="30"
                  />
                </div>
                <div class="contents-info contentsInfoSmall">
                  <div v-if="cart.cabinet_distance">
                    <span class="value positionLabel"
                      ><span class="label">{{
                        $t("checkCoverage.cabinDistance")
                      }}</span></span
                    >
                    <br /><br /><br />
                  </div>
                  <span class="value positionLabel"
                    ><span class="label">{{
                      $t("checkCoverage.speedDownload")
                    }}</span></span
                  >
                  <br /><br /><br />
                  <span class="value positionLabel"
                    ><span class="label">{{
                      $t("checkCoverage.speedUpload")
                    }}</span></span
                  >
                </div>
                <div class="contents-info contentsInfoXSmall">
                  <div v-if="cart.cabinet_distance">
                    <span class="detailThree positionLabel">{{
                      cart.cabinet_distance
                    }}</span>
                    <small class="detailTwo positionLabel">{{
                      $t("checkCoverage.meters")
                    }}</small>
                    <br /><br /><br />
                  </div>
                  <span class="detailThree positionLabel"
                    >{{
                      cart.network_access_details.commercial_profile
                        .downstream_bandwidth
                    }}
                    <span class="detailTwo">Mbps</span></span
                  >
                  <br /><br /><br />
                  <span class="detailThree positionLabel"
                    >{{
                      cart.network_access_details.commercial_profile
                        .upstream_bandwidth
                    }}
                    <span class="detailTwo">Mbps</span></span
                  >
                </div>
              </div>
            </div>

            <div
              class="mdl-cell mdl-cell--10-col viewDetails"
              :class="'viewDetails-' + cart.id"
            >
              <hr />
            </div>

            <div
              class="mdl-cell mdl-cell--12-col content-elms contentElmsDetails"
              :class="'viewDetails-' + cart.id"
            >
              <div
                class="viewDetails viewDetails-0-2"
                :class="'viewDetails-' + cart.id"
              >
                <div class="contents-icons"><br /><br /></div>
                <div class="contents-info contentsInfoSmall">
                  <span class="value positionLabel"
                    ><span class="label">{{
                      $t("checkCoverage.detailLabelTech")
                    }}</span></span
                  >
                  <br /><br /><br />
                  <span class="value positionLabel"
                    ><span class="label">{{
                      $t("checkCoverage.detailLabelCircuit")
                    }}</span></span
                  >
                </div>
                <div class="contents-info contentsInfoXSmall">
                  <span class="detailThree positionLabel">{{
                    cart.network_access_details.technology
                  }}</span>
                  <br /><br /><br />
                  <span class="detailThree positionLabel">{{
                    getDownUp(
                      cart.network_access_details.commercial_profile
                        .downstream_bandwidth,
                      cart.network_access_details.commercial_profile
                        .upstream_bandwidth
                    )
                  }}</span>
                </div>
              </div>
            </div>

            <div
              class="mdl-cell mdl-cell--10-col viewDetails"
              :class="'viewDetails-' + cart.id"
            >
              <hr />
            </div>

            <div
              class="mdl-cell mdl-cell--12-col viewDetails"
              :class="'viewDetails-' + cart.id"
            >
              <div class="mdl-grid" style="width: 100%">
                <div class="mdl-cell mdl-cell--12-col">
                  <div class="boxPrices">
                    <p class="detailPrice">
                      {{ $t("cart.titlePriceSelected") }}
                    </p>

                    <div class="content-prices">
                      <p class="priceLabel">{{ $t("cart.priceActivation") }}</p>
                      <p class="price">€{{ getPrice(cart.base_price.nrc) }}</p>
                    </div>
                    <div class="content-prices">
                      <p class="priceLabel">{{ $t("cart.forMonth") }}</p>
                      <p class="price">€{{ getPrice(cart.base_price.mrc) }}</p>
                    </div>
                    <div class="viewDetails" :class="'viewDetails-' + cart.id">
                      <hr />
                      <p class="detailPrice">
                        {{ $t("cart.titlePriceOther") }}
                      </p>

                      <div
                        v-if="typeof cart.related_products.products == 'object'"
                      >
                        <div
                          v-for="(products, key) in cart.related_products"
                          :key="key"
                        >
                          <div
                            v-for="(product, jey) in products.products"
                            :key="jey"
                          >
                            <div class="content-prices">
                              <p class="priceLabel">
                                {{ $t("cart.priceActivation") }}
                                {{ product.title }} ({{ $t("cart.qtyLabel") }}
                                {{ product.quantity }})
                              </p>
                              <p class="price">
                                €{{ getPrice(product.price.nrc) }}
                              </p>
                            </div>
                            <div class="content-prices">
                              <p class="priceLabel">
                                {{ $t("cart.forMonth") }}
                                {{ product.title }} ({{ $t("cart.qtyLabel") }}
                                {{ product.quantity }})
                              </p>
                              <p class="price">
                                €{{ getPrice(product.price.mrc) }}
                              </p>
                            </div>
                            <br /><br />
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div
                          v-for="(products, key) in cart.related_products"
                          :key="key"
                        >
                          <div class="content-prices">
                            <p class="priceLabel">
                              {{ $t("cart.priceActivation") }}
                              {{ getTitleProduct(products) }} ({{
                                $t("cart.qtyLabel")
                              }}
                              {{ getQuantity(products) }})
                            </p>
                            <p class="price">
                              €{{ getPriceProduct(products, "nrc") }}
                            </p>
                          </div>
                          <div class="content-prices">
                            <p class="priceLabel">
                              {{ $t("cart.forMonth") }}
                              {{ getTitleProduct(products) }} ({{
                                $t("cart.qtyLabel")
                              }}
                              {{ getQuantity(products) }})
                            </p>
                            <p class="price">
                              €{{ getPriceProduct(products, "mrc") }}
                            </p>
                          </div>
                          <br /><br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mdl-cell mdl-cell--2-col"></div>
              </div>
            </div>

            <div class="mdl-cell mdl-cell--2-col"></div>
          </div>
        </div>
        <div v-else>
          <CartItem
            :cart="cart"
            :cartKey="key"
            @open-remove-item-modal="modalRemoveItem"
          />
        </div>
      </div>
      <!-- <br /> -->
    </div>
    <br v-if="carts.length > 0" />
    <div class="contentTotal" v-if="carts.length > 0">
      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--12-col" style="margin-right: 0">
          <span class="labelTwo">{{ $t("remoteHands.infoOrder") }}</span>
        </div>
        <div
          class="mdl-cell mdl-cell--12-col"
          style="display: flex; justify-content: flex-end"
        >
          <div class="cdl-input" style="margin-right: 0">
            <input
              type="text"
              name="client_po"
              v-model="clientPo"
              maxlength="255"
              :class="{ 'not-empty': clientPo }"
            />
            <label>{{ $t("checkCoverage.orderRef") }}</label>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--6-col"></div>
      </div>
      <p class="labelTotal">{{ $t("cart.total") }}</p>
      <p class="textTotal">{{ total.toFixed(2).replace(".", ",") }}€</p>
      <p class="labelTotal">{{ $t("cart.totalMonths") }}</p>
      <p class="textTotal">{{ totalMonths.toFixed(2).replace(".", ",") }}€</p>

      <br /><br /><br />

      <div class="contentOptional">
        <div class="contentInputInline">
          <div class="cirle contentInputRadioLabel">
            <div
              class="buttonRadio buttonRadioPageCart"
              @click="ctrlConditions()"
            >
              <div id="buttonRadioPageCart"></div>
            </div>
            <div>
              <span class="labelInputRadio">{{
                $t("checkCoverageSummary.conditions")
              }}</span>
            </div>
          </div>
        </div>
        <p class="error" v-if="getError()" style="text-align: left">
          {{ $t("checkCoverageSummary.conditionsRequired") }}
        </p>
      </div>
    </div>
    <div
      class="contentButtonDouble"
      v-if="carts.length > 0"
      style="display: block; width: 350px; margin: 0 auto; margin-top: 20px"
    >
      <div
        id="goOrder"
        class="cdlButtonSingle green"
        :class="[confirmed ? '' : 'btnDisabled']"
        @click="confirmOrder()"
        style="font-size: 1.25rem"
      >
        {{ $t("cart.submit") }}
      </div>
      <div>
        <p
          class="deleteOrder"
          id="deleteOrder"
          @click="modalRemoveAllItems(carts)"
        >
          {{ $t("cart.deleteOrder") }}
        </p>
      </div>
    </div>
    <div v-else>
      <h4 class="titleCartEmpty">{{ $t("cart.cartEmpty") }}</h4>
      <p class="subTitleCartEmpty" v-if="false">
        {{ $t("cart.cartEmptyText") }}
      </p>
      <div class="contentButtonDouble" v-if="false">
        <div class="cdlButtonSingle" style="width: 160px" @click="newOrder()">
          {{ $t("cart.newSupply") }}
        </div>
      </div>
    </div>
    <ModalSolutions :viewModal="viewModalSolutions" @close="newOrder" />
  </div>

  <MyLoading :viewLoader="viewLoader" type="full" />
</template>

<style lang="scss">
.btnDisabled {
  opacity: 0.3 !important;
  cursor: default !important;
  &:hover {
    opacity: 0.3;
  }
}
</style>

<script setup>
import { computed } from "vue";
import { useCartStore } from "@/stores/CartStore";
import CartItem from "@/components/CartItem.vue";

const carts = computed(() => {
  return useCartStore().cart;
});

const total = computed(() => {
  return useCartStore().total;
});

const totalMonths = computed(() => {
  return useCartStore().totalMonths;
});
</script>

<script>
import MyBanner from "@/components/MyBanner.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import "@/assets/scss/MyCart.scss";
import { useNetworkCoverageKitStore } from "@/stores/NetworkCoverageKitStore";
import MyLoading from "@/components/MyLoading.vue";
import ModalSolutions from "@/components/ModalSolutions.vue";
import moment from "moment";

import "@/assets/scss/TicketsList.scss";

export default {
  name: "CartPage",
  props: ["openModal"],
  components: {
    MyBanner,
    MyLoading,
    MyHeaderPage,
    ModalSolutions,
  },
  data() {
    return {
      viewLoader: false,
      conditions: null,
      errorConditions: false,
      IsMobile: this.$IsMobile(),
      viewModalSolutions: false,
      confirmed: false,
      clientPo: "",
      multiSelected: {
        period: {
          onOff: false,
          string: "Tutto",
          values: [],
          items: [],
        },
      },
    };
  },
  methods: {
    getIcon(technology) {
      let rls = this.$KitStyle(technology, "icon");
      return rls;
    },
    getClassKit(technology) {
      let rls = this.$KitStyle(technology, "class");
      return rls;
    },
    getAddress(address) {
      return (
        address.address.toUpperCase() +
        " " +
        address.number +
        " " +
        address.city.toUpperCase() +
        " (" +
        address.province.toUpperCase() +
        ")"
      );
    },
    getPrice(price) {
      return this.$PriceClean(price ?? 0);
    },
    newOrder() {
      // return this.$router.push({ name: 'NetworkCoverage' + this.$i18n.locale });
      this.viewModalSolutions = !this.viewModalSolutions;
      if (this.viewModalSolutions) {
        document.getElementsByTagName("body")[0].classList.add("overflow");
      } else {
        document.getElementsByTagName("body")[0].classList.remove("overflow");
      }
    },
    getItem(cart) {
      window.scrollTo(0, 0);

      if (localStorage.getItem("cdlOrderNetworkCoverage")) {
        let order = JSON.parse(localStorage.getItem("cdlOrderNetworkCoverage"));
        if (order.form.cart_id) {
          localStorage.removeItem("cdlOrderKit");
          localStorage.removeItem("cdlOrderKitAddress");
          localStorage.removeItem("cdlOrderNetworkCoverage");
          localStorage.removeItem("cdlOrderNetworkCoverageConditions");
        } else {
          let params = { type: "deleteOrderWizardNetworkTmp" };
          this.$emit("openModal", params);
          return;
        }
      }

      this.viewLoader = false;
      document.getElementsByTagName("body")[0].classList.add("overflow");

      let he = {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      };

      const self = this;

      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "sales/v1/kit/" +
            cart.kit_id,
          { headers: he }
        )
        .then((response) => {
          const networkCoverageKitStore = useNetworkCoverageKitStore();
          networkCoverageKitStore.setKit(response.data, this.$i18n.locale);
          networkCoverageKitStore.setAddress({
            address: cart.network_access_details.address.address,
            streetNumber: cart.network_access_details.address.number,
            city: cart.network_access_details.address.city,
            province: cart.network_access_details.address.province,
          });
          let kitTmp = response.data;
          kitTmp.technology = cart.network_access_details.technology;
          kitTmp.commercial_profile =
            cart.network_access_details.commercial_profile;
          kitTmp.carrier = cart.network_access_details.carrier;
          localStorage.setItem("cdlOrderKit", JSON.stringify({ kit: kitTmp }));

          cart.kit_id = response.data.id;
          const cartStore = useCartStore();
          cartStore.getItem(cart);
          document.getElementsByTagName("body")[0].classList.remove("overflow");
          return this.$router.push({
            name: "OrderNetworkCoverageSummary" + this.$i18n.locale,
          });
        })
        .then(() => {
          self.viewLoader = true;
        })
        .catch(function (err) {
          console.log(err);
          self.viewLoader = true;
          document.getElementsByTagName("body")[0].classList.remove("overflow");
          let params = {
            type: "KitNotFound",
            cart_id: cart.id,
            address: cart.network_access_details.address,
          };
          self.$emit("openModal", params);
          return;
        });
    },
    modalRemoveItem(cart_id) {
      this.$emit("openModal", { type: "removeItemCart", cart_id: cart_id });
    },
    modalRemoveAllItems(carts) {
      this.$emit("openModal", { type: "removeAllItems", carts: carts });
    },
    confirmOrder() {
      if (!this.conditions) {
        this.errorConditions = true;
        return;
      } else {
        this.errorConditions = false;
      }

      this.viewLoader = false;
      window.scrollTo(0, 0);
      let self = this;

      let he = {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      };
      const requestBody = this.clientPo ? { client_po: this.clientPo } : {};

      // Include client_po in the body of the request
      this.axios
        .post(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + "sales/v1/order",
          requestBody,
          { headers: he }
        )
        .then(() => {
          this.$UpdateCart();
          self.viewLoader = true;
          this.$emit("openModal", { type: "confirmOrder" });
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    getExpired(dateCart) {
      let nameDay = moment(
        new Date(dateCart),
        "YYYY-MM-DD",
        this.$i18n.locale,
        true
      ).format("dddd");
      let date = moment(
        new Date(dateCart),
        "YYYY-MM-DD, hh:mm:ss",
        this.$i18n.locale,
        true
      ).format("LL");
      return nameDay.charAt(0).toUpperCase() + nameDay.slice(1) + " " + date;
    },
    getExpiredTime(dateCart) {
      let time = moment(
        new Date(dateCart),
        "HH:mm:ss",
        this.$i18n.locale,
        true
      ).format("HH:mm");
      return time;
    },
    viewDetails(cart_id) {
      let allClasses = document.getElementsByClassName(
        "viewDetails-" + cart_id
      );
      if (
        document
          .getElementsByClassName("viewDetails-" + cart_id)[0]
          .classList.contains("active")
      ) {
        for (let i = 0; i < allClasses.length; i++) {
          if (allClasses[i].value != "") {
            allClasses[i].classList.remove("active");
          }
        }
        document
          .getElementsByClassName("iconDetails-" + cart_id)[0]
          .classList.remove("rotate");
      } else {
        for (let i = 0; i < allClasses.length; i++) {
          if (allClasses[i].value != "") {
            allClasses[i].classList.add("active");
          }
        }
        document
          .getElementsByClassName("iconDetails-" + cart_id)[0]
          .classList.add("rotate");
      }
    },
    getCabinetDistance(cart) {
      return cart.network_access_details.commercial_profile.cabinet_distance;
    },
    getDownUp(download, upload) {
      let down = download + "Mb";
      if (download >= 1000) {
        down = download / 1000 + "Gb";
      }

      let up = upload + "Mb";
      if (upload >= 1000) {
        up = upload / 1000 + "Gb";
      }

      return down + "/" + up;
    },
    ctrlConditions() {
      this.confirmed = true;
      let elm = document.getElementsByClassName("buttonRadioPageCart")[0];
      if (elm.classList.contains("active")) {
        elm.classList.remove("active");
        this.conditions = null;
      } else {
        this.conditions = 1;
        elm.classList.add("active");
        localStorage.setItem("cdlOrderNetworkCoverageConditions", 1);
      }
    },
    getError() {
      return this.errorConditions;
    },
    getTitleProduct(product) {
      return product.products[0]
        ? product.products[0].title
        : product.products.title;
    },
    getPriceProduct(product, type) {
      let prod = product.products[0] ?? product.products;
      if (type == "nrc") {
        return this.getPrice(prod.price.nrc);
      }
      return this.getPrice(prod.price.mrc);
    },
    getQuantity(product) {
      let prod = product.products[0] ?? product.products;
      return prod.quantity == 0 ? 1 : prod.quantity;
    },
    openMultiselect(type) {
      // let ctrl = true;
      // let selected = [];
      let pointer = "";
      switch (type) {
        case "state":
          pointer = this.multiSelected.state;
          break;
        case "period":
          pointer = this.multiSelected.period;
          break;
        // case 'user_refs':
        //   pointer = this.multiSelected.user_refs;
        //   break;
      }

      (function (x) {
        x.onOff = !x.onOff;
        // ctrl = x.onOff;
        // selected = x.values;
      })(pointer);

      // if (ctrl && selected.length > 0) {
      //   console.log("uno")
      //   // let checkBox = document.getElementsByClassName(type);
      //   // setTimeout(() => {
      //   //   for (let i = 0; i < checkBox.length; i++) {
      //   //     checkBox[i].checked = false;
      //   //     selected.forEach(elm => {
      //   //       if (checkBox[i].value == elm) {
      //   //         checkBox[i].checked = true;
      //   //       }
      //   //     });
      //   //   }
      //   // }, 100);
      // }
      // else if (ctrl && selected.length == 0) {

      //   console.log("uno")
      //   // let classAll = type + "All";
      //   // setTimeout(() => {
      //   //   document.getElementsByClassName(classAll)[0].checked = true;
      //   // }, 100);
      // }
    },
    setPage(url) {
      return this.$router.push(url);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$OrderDraft();
    this.viewLoader = true;
  },
};
</script>
