<template>
  <div class="">
    <!-- Form Fields -->
    <!-- Datacenter, Cage, Rack Selection -->
    <div class="mdl-grid padding0 paddingBottom16 noMargin">
      <div class="mdl-cell mdl-cell--12-col">
        <span class="labelTwo">{{ $t("remoteHands.infoRh") }}*</span>
      </div>

      <!-- Datacenter Selection -->
      <div class="mdl-cell mdl-cell--4-col marginPadding0 marginRight16">
        <div class="contentSelect">
          <select
            name="datacenter"
            @input="label($event)"
            @change="setDc()"
            v-model="fields.datacenter"
          >
            <option value="">
              {{ $t("remoteHands.valueDefault") }}
              {{ $t("remoteHands.labelDatacenter") }}
            </option>
            <option
              :value="dcItem"
              v-for="(dcItem, dcIndex) in dc"
              :key="dcIndex"
            >
              {{ dcItem.name }}
            </option>
          </select>
          <span
            class="error marginLeft8"
            v-if="
              v$.fields.datacenter.$invalid &&
              v$.fields.datacenter.$dirty &&
              v$.fields.datacenter.required.$invalid
            "
          >
            {{ $t("remoteHands.labelDatacenter") }}
            {{ $t("checkCoverage.isRequired") }}
          </span>
        </div>
      </div>

      <!-- Cage Selection -->
      <div class="mdl-cell mdl-cell--4-col marginPadding0 marginRight16">
        <div class="contentSelect">
          <select
            name="cage"
            @change="setRoom()"
            @input="label($event)"
            v-model="fields.cage"
          >
            <option value="">
              {{ $t("remoteHands.valueDefault") }}
              {{ $t("remoteHands.labelRoom") }}
            </option>
            <option
              :value="room"
              v-for="(room, roomIndex) in rooms"
              :key="roomIndex"
            >
              {{ room.name }}
            </option>
          </select>
          <p
            class="error marginLeft8"
            v-if="v$.fields.cage.$invalid && v$.fields.cage.$dirty"
          >
            {{ $t("remoteHands.labelRoom") }}
            {{ $t("checkCoverage.isRequired") }}
          </p>
        </div>
      </div>

      <!-- Rack Selection -->
      <div class="mdl-cell mdl-cell--4-col marginPadding0 marginRight16">
        <div class="contentSelect">
          <select
            name="rack"
            @change="submit($event)"
            @input="label($event)"
            v-model="fields.rack"
          >
            <option value="">
              {{ $t("remoteHands.valueDefault") }}
              {{ $t("remoteHands.labelRack") }}
            </option>
            <option
              :value="rack"
              v-for="(rack, rackIndex) in racks"
              :key="rackIndex"
            >
              {{ rack.name }}
            </option>
          </select>
          <p
            class="error marginLeft8"
            v-if="v$.fields.rack.$invalid && v$.fields.rack.$dirty"
          >
            {{ $t("remoteHands.labelRack") }}
            {{ $t("checkCoverage.isRequired") }}
          </p>
        </div>
      </div>
    </div>

    <!-- Intervento* and Finestra temporale* -->
    <div class="mdl-grid padding0 noMargin">
      <div
        class="mdl-cell mdl-cell--6-col noMargin padding0"
        style="margin-left: 0"
      >
        <span class="labelTwo">{{ $t("remoteHands.infoOperation") }}*</span>
        <div class="contentSelect">
          <select
            name="intervention_type"
            @change="
              handleFieldUpdate('intervention_type', fields.intervention_type)
            "
            v-model="fields.intervention_type"
          >
            <option value="">
              {{ $t("remoteHands.request.selectUrgency") }}
            </option>
            <option
              v-for="option in interventionTypeOptions"
              :key="option.id"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>
          <span
            class="error marginLeft8"
            v-if="
              v$.fields.intervention_type.$invalid &&
              v$.fields.intervention_type.$dirty &&
              v$.fields.intervention_type.required.$invalid
            "
          >
            {{ $t("remoteHands.request.urgencyRequired") }}
          </span>
        </div>
      </div>

      <div class="mdl-cell mdl-cell--6-col">
        <span class="labelTwo"
          >{{ $t("remoteHands.request.timeWindow") }}*</span
        >
        <div class="contentSelect">
          <select
            name="timing"
            @change="handleFieldUpdate('timing', fields.timing)"
            v-model="fields.timing"
            :disabled="timingDisabled"
          >
            <option value="">
              {{ $t("remoteHands.request.selectTimeWindow") }}
            </option>
            <option
              v-for="option in filteredTimeWindowOptions"
              :key="option.id"
              :value="option.value"
              :disabled="option.disabled"
            >
              {{ option.label }}
            </option>
          </select>
          <span
            class="error marginLeft8"
            v-if="
              v$.fields.timing.$invalid &&
              v$.fields.timing.$dirty &&
              v$.fields.timing.required.$invalid
            "
          >
            {{ $t("remoteHands.request.timeWindowRequired") }}
          </span>
        </div>
      </div>
    </div>

    <!-- Inizio attività* -->
    <div class="mdl-grid padding0 noMargin">
      <div class="mdl-cell mdl-cell--6-col" style="margin-left: 0">
        <span class="labelTwo"
          >{{ $t("remoteHands.request.activityStart") }}*</span
        >
        <div class="cdl-input">
          <flat-pickr
            v-model="fields.cf_inizio_attivita"
            :config="dateTimePickerConfig"
            @change="
              handleFieldUpdate('cf_inizio_attivita', fields.cf_inizio_attivita)
            "
          ></flat-pickr>
          <span
            class="error"
            v-if="
              v$.fields.cf_inizio_attivita.$invalid &&
              v$.fields.cf_inizio_attivita.$dirty &&
              v$.fields.cf_inizio_attivita.required.$invalid
            "
          >
            {{ $t("remoteHands.request.activityStartRequired") }}
          </span>
        </div>
      </div>
    </div>

    <!-- Intervention Type and Description -->
    <hr />

    <div class="mdl-grid padding0">
      <!--      <div class="mdl-cell mdl-cell&#45;&#45;12-col">-->
      <!--        &lt;!&ndash; Additional Info &ndash;&gt;-->
      <!--        <p class="labelTypeOperationOne marginTextOperation">-->
      <!--          {{ $t("remoteHands.typeOperationOne") }}-->
      <!--        </p>-->
      <!--        <p class="marginTextOperation">-->
      <!--            <span class="labelTypeOperationTwo">{{-->
      <!--                $t("remoteHands.typeOperationTwo")-->
      <!--              }}</span>-->
      <!--          &nbsp;-->
      <!--          <span class="text">{{ $t("remoteHands.typeOperationThree") }}</span>-->
      <!--        </p>-->
      <!--        <p class="marginTextOperation">-->
      <!--            <span class="labelTypeOperationTwo">{{-->
      <!--                $t("remoteHands.typeOperationFour")-->
      <!--              }}</span>-->
      <!--          &nbsp;-->
      <!--          <span class="text">{{ $t("remoteHands.typeOperationFive") }}</span>-->
      <!--        </p>-->
      <!--      </div>-->
    </div>
    <!-- Sommario* -->
    <div class="mdl-grid padding0">
      <div class="mdl-cell mdl-cell--12-col">
        <div class="cdl-textarea">
          <textarea
            maxlength="255"
            name="cf_sommario"
            v-model="fields.cf_sommario"
            @change="handleFieldUpdate('cf_sommario', fields.cf_sommario)"
          ></textarea>
          <label>{{ $t("remoteHands.summary") }}*</label>
          <span
            class="error"
            v-if="
              v$.fields.cf_sommario.$invalid &&
              v$.fields.cf_sommario.$dirty &&
              v$.fields.cf_sommario.required.$invalid
            "
          >
            {{ $t("remoteHands.summaryRequired") }}
          </span>
        </div>
      </div>
    </div>
    <!-- Attachments Upload -->
    <hr />

    <!-- Description -->
    <div class="mdl-cell mdl-cell--12-col marginPadding0">
      <br />
      <div class="cdl-textarea" style="margin-left: 8px">
        <textarea
          maxlength="255"
          name="description"
          id="textareaTwo"
          v-model="fields.description"
          @input="label($event)"
          @change="submit"
        ></textarea>
        <label>{{ $t("remoteHands.description") }}*</label>
        <span
          class="error"
          v-if="
            v$.fields.description.$invalid &&
            v$.fields.description.$dirty &&
            v$.fields.description.required.$invalid
          "
        >
          {{ $t("remoteHands.description") }}
          {{ $t("checkCoverage.isRequired") }}
        </span>
      </div>
    </div>

    <div class="contentForm">
      <h2 class="title">{{ $t("remoteHands.subTitleStep1") }}</h2>
      <span class="required" v-if="false">
        * {{ $t("checkCoverage.required") }}
      </span>

      <!-- Attachments Section -->
      <div class="mdl-grid padding0">
        <div class="mdl-cell mdl-cell--12-col">
          <p class="labelTwo" style="margin-bottom: 0">
            {{ $t("shipping.uploadAttach") }}
          </p>
          <span class="subTitleAttach">
            <strong>
              {{
                $t("shipping.uploadAttachSubTitle", {
                  total: nAttach,
                  size: getLimitSizeAttach(),
                })
              }}
            </strong>
          </span>
          <br />
          <span class="error" v-if="errorAttach">
            {{ $t("shipping.attachFormat", { format: getFormatNameAttach() }) }}
          </span>
          <span class="error" v-if="errorAttachSize">
            {{ $t("shipping.attachSize", { size: getLimitSizeAttach() }) }}
          </span>
          <br /><br />
        </div>
        <div class="mdl-cell mdl-cell--12-col listAttach">
          <!-- Attachments Fields -->
          <div
            v-for="index in nAttach"
            :key="index"
            :class="{
              hover:
                index === 1 ||
                (fields.attachments[index - 2] &&
                  !fields.attachments[index - 1]),
            }"
          >
            <img
              v-if="!fields.attachments[index - 1]"
              :src="
                index === 1 || fields.attachments[index - 2]
                  ? require('@/assets/img/shipping/free_attach.png')
                  : require('@/assets/img/shipping/no_attach.png')
              "
              alt="attach"
            />
            <img
              v-if="fields.attachments[index - 1]"
              :src="require('@/assets/img/shipping/complete_attach.png')"
              alt="attach"
            />
            <span
              class="material-icons deleteAttach"
              v-if="fields.attachments[index - 1]"
              @click="removeAttach(index - 1)"
              >cancel</span
            >
            <p v-if="fields.attachments[index - 1]" class="fileName">
              {{ fields.attachments[index - 1].name }}
            </p>
            <input
              type="file"
              @change="onFileChanged($event, index - 1)"
              :accept="getFormatAttach()"
              capture
              class="fileInput"
            />
          </div>
        </div>
      </div>

      <!-- Purchase Order -->
      <br /><br /><br />
      <hr />

      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--12-col marginPadding0">
          <span class="labelTwo">{{ $t("remoteHands.infoOrder") }}</span>
        </div>
        <div class="mdl-cell mdl-cell--6-col marginPadding0">
          <div class="cdl-input">
            <input
              type="text"
              maxlength="100"
              name="purchase_order"
              v-model="fields.purchase_order"
              @change="submit"
              @input="label($event)"
            />
            <label>{{ $t("remoteHands.order") }}</label>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--6-col"></div>
      </div>

      <!-- Other Information -->
      <hr />

      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--12-col">
          <span class="labelTwo">{{ $t("remoteHands.otherInfo") }}</span>
        </div>
      </div>

      <RefTicket @update="updateRef" />

      <!-- Buttons -->
      <div class="cdlContentButtonDouble">
        <div
          class="cdlButtonSingle width170"
          @click="goBack()"
          :class="{ notAllowed: fields.fromAccessDc }"
        >
          {{ $t("checkCoverage.stepsButtonTwo") }}
        </div>
        <div
          class="cdlButtonSingle green width170"
          id="nextStep"
          @click="goNext()"
        >
          {{ $t("checkCoverage.stepsButtonOne") }}
        </div>
      </div>

      <p class="deleteOrder" @click="cancelOrder()" v-if="!fields.fromAccessDc">
        {{ $t("remoteHands.deleteTicket") }}
      </p>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import RefTicket from "@/components/RefTicket.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "OptionalFields",
  components: {
    flatPickr,
    RefTicket,
  },
  props: {
    initialData: {
      type: Object,
      default: () => ({}),
    },
    dcOptions: {
      type: Array,
      default: () => [],
    },
    roomsOptions: {
      type: Array,
      default: () => [],
    },
    racksOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      dateTimePickerConfig: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        time_24hr: true,
        minuteIncrement: 5,
        minDate: "today",
      },
      fields: {
        timing: "",
        cf_inizio_attivita: "-",
        cf_sommario: "",
        datacenter: "",
        cage: "",
        rack: "",
        intervention_type: "",
        attachments: [],
        purchase_order: null,
        description: null,
        ref_name: null,
        ref_surname: null,
        ref_phone: null,
        ref_email: null,
        fromAccessDc: false,
      },
      dc: [],
      rooms: [],
      racks: [],
      nAttach: 5,
      errorAttach: false,
      sizeAttach: 0,
      errorAttachSize: false,
      cf_inizio_attivitaDisabled: false,
      datacenterDisabled: false,
      cageDisabled: false,
      rackDisabled: false,
    };
  },
  computed: {
    interventionTypeOptions() {
      return [
        {
          id: 1,
          value: this.$t("remoteHands.urgenza.pianificato"),
          label: this.$t("remoteHands.urgenza.pianificato"),
        },
        {
          id: 2,
          value: this.$t("remoteHands.urgenza.urgente"),
          label: this.$t("remoteHands.urgenza.urgente"),
        },
      ];
    },
    timingDisabled() {
      // Highest Priority: 'Urgent' intervention
      if (
        this.fields.intervention_type === this.$t("remoteHands.urgenza.urgente")
      ) {
        return true;
      }

      // Second Priority: Date/time after 18:00
      if (this.fields.cf_inizio_attivita) {
        const selectedDate = new Date(this.fields.cf_inizio_attivita);
        const selectedHour = selectedDate.getHours();
        if (selectedHour >= 18) {
          return true;
        }
      }

      // Default: 'timing' select is enabled
      return false;
    },
    timingOptions() {
      return [
        {
          id: 1,
          value: this.$t("remoteHands.finestra_temporale.bt_orario_ufficio"),
          label: this.$t("remoteHands.finestra_temporale.bt_orario_ufficio"),
        },
        {
          id: 2,
          value: this.$t(
            "remoteHands.finestra_temporale.etb_fuori_orario_ufficio"
          ),
          label: this.$t(
            "remoteHands.finestra_temporale.etb_fuori_orario_ufficio"
          ),
        },
      ];
    },
    filteredTimeWindowOptions() {
      const isDatacenterE100 =
        this.fields.datacenter && this.fields.datacenter.name === "E100"; // Adjust based on your datacenter identification

      return this.timingOptions.map((option) => {
        let disabled = false;

        // Disable 'EBT' option if datacenter is 'e100'
        if (
          isDatacenterE100 &&
          option.value ===
            this.$t("remoteHands.finestra_temporale.etb_fuori_orario_ufficio")
        ) {
          disabled = true;
          if( this.fields.timing != this.$t("remoteHands.finestra_temporale.bt_orario_ufficio") ) {
            this.handleFieldUpdate('timing', '');
          }
        }

        return { ...option, disabled };
      });
    },
    finestraTemporaleRequired() {
      const isUrgent =
        this.fields.intervention_type ===
        this.$t("remoteHands.urgenza.urgente");
      return isUrgent ? false : required;
    },
    // cf_inizio_attivitaRequired() {
    //   const isUrgent =
    //     this.fields.intervention_type ===
    //     this.$t("remoteHands.urgenza.urgente");
    //   return isUrgent ? false : required;
    // },
    datacenterRequired() {
      const isUrgent =
        this.fields.intervention_type ===
        this.$t("remoteHands.urgenza.urgente");
      return isUrgent ? false : required;
    },
    cageRequired() {
      const isUrgent =
        this.fields.intervention_type ===
        this.$t("remoteHands.urgenza.urgente");
      return isUrgent ? false : required;
    },
    rackRequired() {
      const isUrgent =
        this.fields.intervention_type ===
        this.$t("remoteHands.urgenza.urgente");
      return isUrgent ? false : required;
    },
  },
  validations() {
    return {
      fields: {
        intervention_type: { required },
        timing: { required: this.finestraTemporaleRequired },
        cf_inizio_attivita: { required },
        cf_sommario: { required },
        datacenter: { required: this.datacenterRequired },
        cage: { required: this.cageRequired },
        rack: { required: this.rackRequired },
        description: { required },
      },
    };
  },
  watch: {
    initialData: {
      handler(newData) {
        this.fields = {
          ...this.fields,
          ...newData,
          // Reference data remains intact
          ref_name: this.fields.ref_name,
          ref_surname: this.fields.ref_surname,
          ref_phone: this.fields.ref_phone,
          ref_email: this.fields.ref_email,
        };
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    label($event) {
      this.$emit("label", $event);
    },
    handleFieldUpdate(fieldName, value) {
      this.fields[fieldName] = value;

      if (fieldName === "intervention_type") {
        this.adjustTimingBasedOnInterventionType();
      } else if (
        fieldName === "cf_inizio_attivita" ||
        fieldName === "datacenter"
      ) {
        this.adjustTimingBasedOnDateTime();

        if( fieldName == "cf_inizio_attivita" && this.fields[fieldName] == "" ) {
          this.fields[fieldName] = document.getElementsByClassName("flatpickr-input")[0]?.value;
        }
      }

      this.submit();
    },
    adjustTimingBasedOnDateTime() {
      // Do not adjust 'timing' if 'urgent' is selected
      if (
        this.fields.intervention_type === this.$t("remoteHands.urgenza.urgente")
      ) {
        return;
      }

      const isDatacenterE100 =
        this.fields.datacenter && this.fields.datacenter.name === "e100";

      if (isDatacenterE100) {
        // Set 'timing' to 'ETB' and disable the select
        this.fields.timing = this.$t(
          "remoteHands.finestra_temporale.etb_fuori_orario_ufficio"
        );
        return; // Exit the method to prevent overriding
      }

      if (this.fields.cf_inizio_attivita) {
        const selectedDate = new Date(this.fields.cf_inizio_attivita);
        const selectedHour = selectedDate.getHours();

        if (selectedHour >= 18) {
          if (
            this.fields.timing ===
            this.$t("remoteHands.finestra_temporale.bt_orario_ufficio")
          ) {
            // Change timing from 'BT' to 'ETB'
            this.fields.timing = this.$t(
              "remoteHands.finestra_temporale.etb_fuori_orario_ufficio"
            );
          }
        } else {
          const btOptionDisabled = this.filteredTimeWindowOptions.find(
            (option) =>
              option.value ===
              this.$t("remoteHands.finestra_temporale.bt_orario_ufficio")
          ).disabled;

          if (!btOptionDisabled) {
            this.fields.timing = this.$t(
              "remoteHands.finestra_temporale.bt_orario_ufficio"
            );
          } else {
            this.fields.timing = this.$t(
              "remoteHands.finestra_temporale.etb_fuori_orario_ufficio"
            );
          }
        }
      }
    },
    adjustTimingBasedOnInterventionType() {
      const isUrgent =
        this.fields.intervention_type ===
        this.$t("remoteHands.urgenza.urgente");

      if (isUrgent) {
        this.fields.timing = "";
        this.timingDisabled = true;
        this.cf_inizio_attivitaDisabled = true;
        this.datacenterDisabled = true;
        this.cageDisabled = true;
        this.rackDisabled = true;
        this.fields.cf_inizio_attivita = "";
      } else {
        this.timingDisabled = false;
        this.cf_inizio_attivitaDisabled = false;
        this.datacenterDisabled = false;
        this.cageDisabled = false;
        this.rackDisabled = false;
        this.adjustTimingBasedOnDateTime();
      }
    },
    submit() {
      this.$emit("updateField", this.fields);
    },
    setDc() {
      this.submit();
      this.$emit("fetchRooms", this.fields.datacenter);
    },
    setRoom() {
      this.submit();
      this.$emit("fetchRacks", this.fields.cage);
    },
    updateRef(stepData) {
      Object.assign(this.fields, stepData);
    },
    goNext() {
      this.v$.$validate().then((result) => {
        if (result) {
          this.v$.$reset();
          this.$emit("goNext");
        }
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
    },
    goBack() {
      this.$emit("goBack");
    },
    cancelOrder() {
      this.$emit("cancelOrder");
    },
    onFileChanged($event, index) {
      const target = $event.target;
      if (target && target.files) {
        if (
          this.getFormatAttach().search(target.files[0].type) < 0 ||
          target.files[0].type === ""
        ) {
          this.errorAttach = true;
          return;
        }

        this.errorAttach = false;
        this.errorAttachSize = false;

        this.sizeAttach += parseFloat(this.getSize(target.files[0].size));

        if (this.sizeAttach > this.getLimitSizeAttach()) {
          this.errorAttachSize = true;
          return;
        }

        this.fields.attachments[index] = target.files[0];
        this.submit();
      }
    },
    removeAttach(index) {
      this.fields.attachments.splice(index, 1);
    },
    getSize(sizeInBytes) {
      return (sizeInBytes / (1024 * 1024)).toFixed(2);
    },
    getLimitSizeAttach() {
      return window.VUE_APP_LIMIT_SIZE_ATTACH;
    },
    getFormatAttach() {
      return window.VUE_APP_TYPE_ATTACH;
    },
    getFormatNameAttach() {
      return window.VUE_APP_TYPE_ATTACH_NAME.replaceAll("&nbsp;", " ");
    },
  },
  created() {
    Object.assign(this.fields, this.initialData);
    this.dc = this.dcOptions;
    this.rooms = this.roomsOptions;
    this.racks = this.racksOptions;
  },
};
</script>

<style scoped>
/* Include any necessary styles here */
</style>
