<template>
  <div class="requestConfirmed">
    <div class="requestConfirmedContent">
      <div class="head">
        <h3>{{ $t("help.confirmedTitle") }}</h3>
        <p>{{ $t("help.confirmedSubTitle") }}</p>
        <slot name="info"></slot>
      </div>
      <div class="body">
        <h5>
          {{
            $t("help.confirmedTicketId", {
              confirmedRequestId: props.confirmedRequestId,
            })
          }}
        </h5>
      </div>
      <div class="tail">
        <slot name="tail"></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  confirmedRequestId: String,
});
</script>
