<template>
  <div class="">
    <div class="contentForm">
      <h2 class="title">{{ $t("remoteHands.titleStep3") }}</h2>
      <p class="subTitle">{{ $t("remoteHands.subTitleStep3") }}</p>

      <div class="space"></div>

      <div class="mdl-grid padding0">
        <div class="mdl-cell mdl-cell--12-col">
          <span class="labelTwo">{{ $t("remoteHands.infoOperationRh") }}</span>
        </div>
        <div class="mdl-cell mdl-cell--4-col">
          <p>
            <span class="labelConfirm">{{
              $t("remoteHands.labelDatacenter")
            }}</span>
            <span class="valueConfirm">{{ getDatacenter() }}</span>
          </p>
        </div>
        <div class="mdl-cell mdl-cell--4-col">
          <p>
            <span class="labelConfirm">{{ $t("remoteHands.labelRoom") }}</span>
            <span class="valueConfirm">{{ getRoom() }}</span>
          </p>
        </div>
        <div class="mdl-cell mdl-cell--4-col">
          <p>
            <span class="labelConfirm">{{ $t("remoteHands.labelRack") }}</span>
            <span class="valueConfirm">{{ getRack() }}</span>
          </p>
        </div>
        <div class="mdl-cell mdl-cell--12-col" v-if="false">
          <p>
            <span class="labelConfirm">{{ $t("remoteHands.activity") }}</span>
            <span class="valueConfirm">{{ getActivity() }}</span>
          </p>
        </div>
        <div class="mdl-cell mdl-cell--12-col" v-if="false">
          <p>
            <span class="labelConfirm">{{
              $t("remoteHands.typeOperation")
            }}</span>
            <span class="valueConfirm">{{ getOperation() }}</span>
          </p>
        </div>
        <div class="mdl-cell mdl-cell--12-col">
          <p>
            <span class="labelConfirm">{{
              $t("remoteHands.request.timeWindow")
            }}</span>
            <span class="valueConfirm">{{ getTimeWindow() }}</span>
          </p>
        </div>
        <div class="mdl-cell mdl-cell--12-col">
          <p>
            <span class="labelConfirm">{{
              $t("remoteHands.request.activityStart")
            }}</span>
            <span class="valueConfirm">{{ getStartActivity() }}</span>
          </p>
        </div>
        <div class="mdl-cell mdl-cell--12-col">
          <p>
            <span class="labelConfirm">{{
              $t("remoteHands.summary")
            }}</span>
            <span class="valueConfirm">{{ getSummary() }}</span>
          </p>
        </div>
        <div class="mdl-cell mdl-cell--12-col">
          <p>
            <span class="labelConfirm">{{
              $t("remoteHands.description")
            }}</span>
            <span class="valueConfirm">{{ getDescription() }}</span>
          </p>
        </div>
        <div class="mdl-cell mdl-cell--12-col">
          <p>
            <span class="labelConfirm">{{ $t("remoteHands.order") }}</span>
            <span class="valueConfirm">{{ getPo() }}</span>
          </p>
        </div>
      </div>

      <!--      &lt;!&ndash; Insert Read-only Fields Here &ndash;&gt;-->
      <!--      <hr />-->
      <!--      <div class="mdl-grid padding0">-->
      <!--        <div class="mdl-cell mdl-cell&#45;&#45;6-col">-->
      <!--          <span class="labelConfirm">Tipo di richiesta</span>-->
      <!--          <span class="valueConfirm">{{ fields.tipo_di_richiesta }}</span>-->
      <!--        </div>-->
      <!--        <div class="mdl-cell mdl-cell&#45;&#45;6-col">-->
      <!--          <span class="labelConfirm">Livello tecnico</span>-->
      <!--          <span class="valueConfirm">{{ fields.livello_tecnico }}</span>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      <div class="mdl-grid padding0">-->
      <!--        <div class="mdl-cell mdl-cell&#45;&#45;6-col">-->
      <!--          <span class="labelConfirm">Urgenza</span>-->
      <!--          <span class="valueConfirm">{{ fields.urgenza }}</span>-->
      <!--        </div>-->
      <!--        <div class="mdl-cell mdl-cell&#45;&#45;6-col">-->
      <!--          <span class="labelConfirm">Finestra temporale</span>-->
      <!--          <span class="valueConfirm">{{ fields.finestra_temporale }}</span>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      <div class="mdl-grid padding0">-->
      <!--        <div class="mdl-cell mdl-cell&#45;&#45;6-col">-->
      <!--          <span class="labelConfirm">Inizio attività</span>-->
      <!--          <span class="valueConfirm">{{ formatDateTime(fields.inizio_attivita) }}</span>-->
      <!--        </div>-->
      <!--        <div class="mdl-cell mdl-cell&#45;&#45;6-col">-->
      <!--          <span class="labelConfirm">Purchase Order</span>-->
      <!--          <span class="valueConfirm">{{ fields.purchase_order }}</span>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      <div class="mdl-grid padding0">-->
      <!--        <div class="mdl-cell mdl-cell&#45;&#45;12-col">-->
      <!--          <span class="labelConfirm">Sommario</span>-->
      <!--          <span class="valueConfirm">{{ fields.sommario }}</span>-->
      <!--        </div>-->
      <!--      </div>-->

      <!-- Existing Form Fields Continued -->
      <hr />
      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--12-col" style="margin-left: 0">
          <p class="labelTwo" style="margin-bottom: 0">
            {{ $t("shipping.uploadAttach") }}
          </p>
        </div>
        <div
          v-for="(attach, keyattach) in getAttachs()"
          :key="keyattach"
          class="mdl-cell mdl-cell--12-col"
        >
          <a
            href=""
            :download="attach.name"
            @click="downloadFile(attach.file, keyattach)"
            :id="['file-' + keyattach]"
            class="linkA labelConfirm"
            style="margin-left: 0"
            >{{ attach.name }}</a
          ><br />
          <small class="sizeFile">{{ getSize(attach.file.size) }}Mb</small>
        </div>
      </div>
      <div class="mdl-grid padding0">
        <div class="mdl-cell mdl-cell--12-col">
          <span class="labelTwo">{{
            $t("checkCoverage.titleReferences")
          }}</span>
        </div>
        <div class="mdl-cell mdl-cell--12-col">
          <p>
            <span class="labelConfirm">{{ $t("refTicket.nameRef") }}</span>
            <span class="valueConfirm">{{ getNameRef() }}</span>
          </p>
        </div>
        <div class="mdl-cell mdl-cell--6-col">
          <p>
            <span class="labelConfirm">{{ $t("refTicket.phoneRef") }}</span>
            <span class="valueConfirm">{{ getPhoneRef() }}</span>
          </p>
        </div>
        <div class="mdl-cell mdl-cell--6-col">
          <p>
            <span class="labelConfirm">{{ $t("refTicket.emailRef") }}</span>
            <span class="valueConfirm">{{ getEmailRef() }}</span>
          </p>
        </div>
      </div>

      <hr />

      <div class="mdl-grid padding0">
        <div class="mdl-cell mdl-cell--12-col">
          <div class="contentConditions">
            <p class="centered-content">
              <!-- Add a new class for centering -->
              <input
                type="checkbox"
                @change="check()"
                class="check"
                style="width: 20px; height: 20px"
              />
              &nbsp;
              <span class="textConditions">{{
                $t("remoteHands.conditions")
              }}</span>
            </p>
            <p
              class="error"
              v-if="this.v$.conditions.$invalid && this.v$.conditions.$dirty"
            >
              {{ $t("checkCoverage.isRequired") }}
            </p>
          </div>
        </div>
      </div>

      <div v-if="btnSubmit">
        <div
          class="cdlContentButtonDouble"
          style="justify-content: space-around"
        >
          <div class="cdlButtonSingle green width170" @click="goBack()">
            {{ $t("remoteHands.edit") }}
          </div>
          <div
            class="cdlButtonSingle green width170"
            id="nextStep"
            @click="openTicket()"
          >
            {{ $t("remoteHands.submit") }}
          </div>
        </div>

        <p class="deleteOrder" @click="cancelOrder()" v-if="!fromAccessDc">
          {{ $t("remoteHands.deleteTicket") }}
        </p>
      </div>
    </div>
    <MyLoading :viewLoader="this.btnSubmit" type="" />
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import MyLoading from "@/components/MyLoading.vue";

export default {
  name: "ConfirmRh",
  components: {
    MyLoading,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      conditions: { required },
    };
  },
  props: {
    stepData: {
      type: Object,
      required: true,
    },
    wizardData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // Existing data properties
      conditions: null,
      btnSubmit: true,
      objectURL: null,
      fromAccessDc: false,

      // Use the fields passed from the previous step
      fields: {
        tipo_di_richiesta: this.wizardData.tipo_di_richiesta || "",
        livello_tecnico: this.wizardData.livello_tecnico || "",
        urgenza: this.wizardData.urgenza || "",
        finestra_temporale: this.wizardData.finestra_temporale || "",
        inizio_attivita: this.wizardData.inizio_attivita || "",
        purchase_order: this.wizardData.purchase_order || "",
        sommario: this.wizardData.sommario || "",
      },
    };
  },
  methods: {
    // Existing methods
    goBack() {
      this.$emit("goBack");
    },
    cancelTicket() {
      window.scrollTo(0, 0);
      let params = { type: "deleteShippingTicket" };
      this.$emit("openModal", params);
    },
    async openTicket() {
      const result = await this.v$.$validate();
      if (result) {
        this.btnSubmit = false;
        this.v$.$reset();
        this.$emit("openTicket");
      }
      return;
    },
    getDatacenter() {
      return this.wizardData.datacenter.name;
    },
    getRoom() {
      return this.wizardData.cage.name;
    },
    getRack() {
      return this.wizardData.rack.name;
    },
    getActivity() {
      return this.wizardData.activity_type.name;
    },
    getOperation() {
      return this.wizardData.intervention_type;
    },
    getTimeWindow() {
      return this.wizardData.timing;
    },
    getStartActivity() {
      return this.wizardData.cf_inizio_attivita;
    },
    getSummary() {
      return this.wizardData.cf_sommario;
    },
    getPo() {
      return this.wizardData.purchase_order ?? "-";
    },
    getDescription() {
      return this.wizardData.description;
    },
    getNameRef() {
      return this.wizardData.ref_name + " " + this.wizardData.ref_surname;
    },
    getPhoneRef() {
      return this.wizardData.ref_phone;
    },
    getEmailRef() {
      return this.wizardData.ref_email;
    },
    check() {
      this.conditions = this.conditions == null ? true : null;
    },
    viwBtnSubmit() {
      this.btnSubmit = !this.btnSubmit;
    },
    cancelOrder() {
      this.$emit("close");
    },
    getAttachs() {
      let attachs = [];
      this.wizardData.attachments.forEach((element) => {
        attachs.push({
          name: element.name,
          file: element,
        });
      });
      return attachs;
    },
    downloadFile(fileInput, key) {
      if (this.objectURL) {
        URL.revokeObjectURL(this.objectURL);
      }
      this.objectURL = URL.createObjectURL(fileInput);
      document.getElementById("file-" + key).href = this.objectURL;
    },
    getSize(sizeInBytes) {
      return (sizeInBytes / (1024 * 1024)).toFixed(2);
    },
    formatDateTime(dateTime) {
      const date = new Date(dateTime);
      return date.toLocaleString();
    },
  },
  activated() {
    window.scrollTo(0, 0);

    this.btnSubmit = true;
    if (document.getElementsByClassName("check")[0]) {
      document.getElementsByClassName("check")[0].checked = false;
    }
    this.conditions = null;
    this.fromAccessDc = this.wizardData.fromAccessDc;
  },
};
</script>
<style scoped lang="scss">
.contentConditions {
  display: flex; /* Use Flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; /* Make sure it takes the full height */
}

.centered-content {
  display: flex; /* Make the paragraph a flex container */
  align-items: center; /* Center items vertically */
  margin: 0; /* Remove any default margin */
}
</style>
