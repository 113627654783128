<template>
  <div id="btn-container" class="mdl-grid">
    <div
      :class="
        isFromValidation
          ? 'mdl-cell--6-col-phone mdl-cell--6-col'
          : 'mdl-cell--12-col-phone mdl-cell--12-col'
      "
      class="btn-container-item"
    >
      <button
        id="next"
        class="cdlButtonSingle width170"
        @click="handleButtonClick(props.currentStep + 1)"
      >
        {{ $t("crossConnects.step4") }}
      </button>
    </div>
    <div
      v-if="isFromValidation"
      id="btn-validate"
      class="mdl-cell--6-col btn-container-item"
    >
      <button class="cdlButtonSingle" @click="handleButtonClick(5)" id="goToEnd">
        {{ $t("crossConnects.confirmValidation") }}
      </button>
    </div>
    <div
      id="delete-order-btn"
      class="mdl-cell--12-col-phone mdl-cell--12-col btn-container-item"
      @click="toggleModal"
    >
      <span class="deleteOrder" id="deleteOrder"> {{ $t("crossConnects.cancel") }}</span>
    </div>
    <WarningModal
      v-if="showModal"
      :isOpen="showModal"
      @update:isOpen="toggleModal"
    />
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref } from "vue";
import WarningModal from "@/components/CrossConnect/WarningModal.vue";

const emit = defineEmits(["step-button-click"]);
const showModal = ref(false);

const handleButtonClick = (value) => {
  emit("step-button-click", value);
};

const props = defineProps({
  currentStep: {
    type: Number,
    required: true,
  },
  isMobile: {
    type: Boolean,
    required: true,
  },
  isFromValidation: {
    type: Boolean,
    required: true,
  },
});

const toggleModal = () => {
  showModal.value = !showModal.value;
};
</script>

<style scoped lang="scss">
#btn-container {
  margin-bottom: 50px;
  justify-content: space-between;
}
.btn-container-item {
  text-align: center;
}
#delete-order-btn {
  margin-top: 20px;
}
</style>
