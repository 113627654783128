<template>
  <div id="RemoteHands" v-if="isColocation">
    <MyBanner :text="$t('banner.RemoteHandsPage')" />
    <div class="contentSteps">
      <div class="steps">
        <div
          class="step"
          :class="{
            active: getStep() == key + 1,
            complete: getStep() > key + 1,
          }"
          v-for="(listStep, key) in getSteps()"
          :key="key"
        >
          <div>
            <component :is="listStep.icon"></component>
          </div>
          <div>
            <p class="nameStep">
              <span v-if="!IsMobile">{{ key + 1 }}.</span>
              {{ $t(listStep.title) }}
            </p>
            <p class="statusStep" v-if="getStep() == key + 1 && false">
              {{ $t("checkCoverage.statusStep1") }}
            </p>
            <p class="statusStep" v-else-if="getStep() > key + 1 && false">
              {{ $t("checkCoverage.statusStep2") }}
            </p>
            <p class="statusStep" v-else-if="getStep() < key + 1 && false">
              {{ $t("checkCoverage.statusStep3") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="contentFormTwoCol">
      <!-- MOBILE -->
      <div class="contentPrimary contentPrimaryMobile" v-if="IsMobile">
        <h3 @click="openInfoMobile()">
          Remote Hand
          <span class="material-icons" v-if="!infoMobile">expand_more</span>
          <span class="material-icons" v-if="infoMobile">expand_less</span>
        </h3>
        <div v-if="infoMobile">
          <MyStep1 />
          <p class="labelS">{{ $t("remoteHands.what") }}</p>
          <p class="textS">Remote Hands</p>
          <br />
          <p>
            <span class="labelS">{{ $t("remoteHands.activity") }}</span>
            <span class="textS">&nbsp;{{ form.activity_type.name }}</span>
          </p>
          <p>
            <span class="labelS">{{ $t("remoteHands.typeActivity") }}</span>
            <span class="textS">&nbsp;{{ form.intervention_type }}</span>
          </p>
          <hr />
          <img src="@/assets/img/icon_pin.png" alt="location" width="45" />
          <!-- <span class="material-icons locationIcon">location_on</span> -->
          <p class="label">{{ $t("remoteHands.detailLabelLocation") }}</p>
          <p class="text">{{ form.datacenter.address }}</p>
        </div>
      </div>
      <div class="contentPrimary" v-if="!IsMobile">
        <MyStep1 />
        <p class="labelS">{{ $t("remoteHands.what") }}</p>
        <p class="textS">Remote Hands</p>
        <br />
        <p>
          <span class="labelS">{{ $t("remoteHands.activity") }}</span>
          <span class="textS">&nbsp;{{ form.activity_type.name }}</span>
        </p>
        <p>
          <span class="labelS">{{ $t("remoteHands.typeActivity") }}</span>
          <span class="textS">&nbsp;{{ form.intervention_type }}</span>
        </p>
        <hr />
        <img src="@/assets/img/icon_pin.png" alt="location" width="45" />
        <!-- <span class="material-icons locationIcon">location_on</span> -->
        <p class="labelS">{{ $t("remoteHands.detailLabelLocation") }}</p>
        <p class="textS">{{ form.datacenter.address }}</p>
      </div>
      <div class="contentSecondary">
        <keep-alive>
          <component
            :is="steps[step - 1]"
            @update="processStep"
            @goBack="goBack"
            @goNext="goNext"
            @close="cancelTicket"
            :wizard-data="form"
            :step-data="step"
            @openTicket="openTicket"
            ref="RemoteHands"
          ></component>
        </keep-alive>
      </div>
    </div>

    <div>
      <MyError :viewError="viewError" :message="messageError" />
    </div>
  </div>
  <div v-else>
    <MyHeaderPage :title="$t('remoteHands.title')" />
    <CNoSolutionsService :viewBtn="false" />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useUserStore } from "@/stores/UserStore";

const isColocation = computed(() => {
  let colo = false;
  let user = useUserStore().user;
  if (user.isColocation) {
    colo = user.isColocation;
  }
  return colo;
});
</script>

<script>
import "@/assets/scss/RemoteHands.scss";

import MyStep1 from "@/components/RemoteHands/svg/Step1.vue";
import MyStep2 from "@/components/RemoteHands/svg/Step2.vue";
import MyStep3 from "@/components/RemoteHands/svg/Step3.vue";

import ActivityRh from "@/components/RemoteHands/Activity.vue";
import InfoGeneral from "@/components/RemoteHands/InfoGeneral.vue";
import ConfirmRh from "@/components/RemoteHands/ConfirmRh.vue";
import MyError from "@/components/MyError.vue";
import MyBanner from "@/components/MyBanner.vue";
import CNoSolutionsService from "@/components/NoSolutionsService.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";

export default {
  name: "RemoteHands",
  props: ["openModal"],
  components: {
    MyStep1,
    MyStep2,
    MyStep3,
    ActivityRh,
    InfoGeneral,
    ConfirmRh,
    MyError,
    MyBanner,
    CNoSolutionsService,
    MyHeaderPage,
  },
  data() {
    return {
      step: 1,
      listSteps: [
        {
          icon: "MyStep1",
          title: "remoteHands.titleStep1",
        },
        {
          icon: "MyStep2",
          title: "remoteHands.titleStep2",
        },
        {
          icon: "MyStep3",
          title: "remoteHands.titleStep3",
        },
      ],
      steps: ["ActivityRh", "InfoGeneral", "ConfirmRh"],
      form: {
        datacenter: "",
        cage: "",
        rack: "",
        activity_type: "",
        intervention_type: "",
        activity_group: "",
        attachments: [],
        purchase_order: null,
        description: null,
        ref_name: null,
        ref_surname: null,
        ref_phone: null,
        ref_email: null,
        fromAccessDc:
          localStorage.getItem("DcAccessRemoteHands") &&
          localStorage.getItem("DcAccessRemoteHands") == 1
            ? true
            : false,
      },
      viewError: false,
      messageError: "",
      IsMobile: this.$IsMobile(),
      infoMobile: false,
    };
  },
  methods: {
    getStep() {
      return this.step;
    },
    getSteps() {
      return this.listSteps;
    },
    processStep(stepData) {
      Object.assign(this.form, stepData);
    },
    goBack() {
      this.step--;
      window.scrollTo(0, 0);
    },
    goNext() {
      this.step++;
      window.scrollTo(0, 0);
    },
    cancelTicket() {
      window.scrollTo(0, 0);
      let params = { type: "deleteRemoteHandTicket" };
      this.$emit("openModal", params);
    },
    openInfoMobile() {
      this.infoMobile = !this.infoMobile;
    },
    openTicket(name, surname, phone, email) {
      let tmpForm = Object.assign({}, this.form);
      console.log(name, surname, phone, email);
      this.form.datacenter = this.form.datacenter.name;
      this.form.cage = this.form.cage.name;
      this.form.rack = this.form.rack.name;
      this.form.activity_type = this.form.activity_type.name;
      let he = {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
        "Content-Type": "multipart/form-data",
      };

      const self = this;

      this.axios
        .post(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "tickets/v1/remotehands",
          this.form,
          { headers: he }
        )
        .then(() => {
          this.form = {
            datacenter: "",
            cage: "",
            rack: "",
            activity_type: "",
            intervention_type: "",
            activity_group: "",
            attachments: [],
            timing: null,
            cf_inizio_attivita: "",
            cf_sommario: null,
            purchase_order: null,
            description: null,
            ref_name: this.form.ref_name,
            ref_surname: this.form.ref_surname,
            ref_phone: this.form.ref_phone,
            ref_email: this.form.ref_email,
            fromAccessDc: false,
          };
          this.step = 1;
          this.isProgress = 0;
          localStorage.removeItem("DcAccessRemoteHands");
          this.$emit("openModal", { type: "confirmRemoteHand" });
        })
        .catch(function (err) {
          self.viewError = true; //err.message; //"Nessuna copertura";
          self.messageError = err.message;
          self.$refs.RemoteHands.viwBtnSubmit();
          self.form = tmpForm;
          setTimeout(() => {
            self.viewError = false;
          }, 5000);
        });
    },
    buildSteps() {
      const self = this;
      let steps = document.getElementsByClassName("step");
      let newElm = "";
      for (let i = 0; i < steps.length; i++) {
        if (i != steps.length - 1) {
          newElm = document.createElement("div");
          newElm.className = "spacing";
          steps[i].after(newElm);
        }
        if (i == steps.length - 1 && !this.form.fromAccessDc) {
          newElm = document.createElement("span");
          newElm.className = "material-icons closeOrder";
          newElm.innerHTML = "close";
          steps[i].after(newElm);
          newElm.addEventListener("click", function handleClick() {
            self.cancelTicket();
          });
        }
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$IsLoggedIn();
    this.buildSteps();
  },
};
</script>
