<template>
  <div id="CrossConnect" class="relative" v-if="isColocation">
    <div id="Shipping" class="accessRequest">
      <MyBanner
        :text="
          $t('banner.crossConnect', {
            name: user.first_name + ' ' + user.last_name,
          })
        "
      />
      <MyHeaderPage
        :title="$t('crossConnects.title')"
        :subtitle="''"
        :calendar="true"
        :show-modal="showModal"
        button="support"
        @update:showModal="handleToggleModal"
      />

      <br /><br />
      <br /><br />
      <div class="typeShipping">
        <div class="card">
          <div class="cardContent">
            <h3>{{ $t("crossConnects.cardOne") }}</h3>
            <br /><br />
            <p
              :class="isMobile ? 'text-1-mobile' : 'text-1'"
              v-html="$t('crossConnects.subOne')"
              v-if="true"
            ></p>
            <br />
            <div class="contentButton">
              <div
                id="local"
                class="cdlButton"
                @click="
                  router.push({
                    name: 'OrderCrossConnect',
                    params: { isLocal: 'true' },
                  })
                "
              >
                {{ $t("crossConnects.request") }}
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="cardContent">
            <h3>{{ $t("crossConnects.cardTwo") }}</h3>
            <br /><br />
            <p
              :class="isMobile ? 'text-1-mobile' : 'text-1'"
              v-html="$t('crossConnects.subTwo')"
              v-if="true"
            ></p>
            <br />
            <div class="contentButton">
              <div
                id="infracampus"
                class="cdlButton"
                @click="
                  router.push({
                    name: 'OrderCrossConnectIntra',
                    params: { isLocal: 'false' },
                  })
                "
              >
                {{ $t("crossConnects.request") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <br /><br />
    </div>
  </div>
  <div v-else>
    <MyHeaderPage :title="$t('crossConnects.title')" />
    <CNoSolutionsService
      :text="'noSolutionsServices.crossConnect'"
      :viewBtn="false"
    />
  </div>
</template>

<script setup>
import { computed, getCurrentInstance, onMounted, ref } from "vue";
import { useUserStore } from "@/stores/UserStore";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import "@/assets/scss/HomePage.scss";
import router from "@/router";
import MyBanner from "@/components/MyBanner.vue";
import { useDatacenterStore } from "@/stores/DataCenterStore";
import CNoSolutionsService from "@/components/NoSolutionsService.vue";

const user = computed(() => {
  return useUserStore().user;
});
// Access the global properties directly within setup
const { config } = getCurrentInstance().appContext;
const isMobile = ref(false);
const showModal = ref(false);

const handleToggleModal = (newValue) => {
  showModal.value = newValue; // here we use .value because showModal is a ref
};
const datacenterStore = useDatacenterStore();

onMounted(async () => {
  await datacenterStore.clearData();
  isMobile.value = config.globalProperties.$IsMobile();
  window.scrollTo(0, 0); // Scroll to the top of the page
});

const isColocation = computed(() => {
  let colo = false;
  let user = useUserStore().user;
  if (user.isColocation) {
    colo = user.isColocation;
  }
  return colo;
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/Shipping.scss";

#CrossConnect {
  display: flex;
  flex-direction: column;
  min-height: 100vh; // Ensure full height coverage
  padding: 1rem;
  font-size: 1.1rem;
}

.text-1 {
  width: 80%;
  height: 150px;
  font-size: $font19_2;
  color: $textGray;
  text-align: justify;
  margin: 0 auto;

  b,
  strong {
    font-weight: bold !important; /* Ensure bold styling */
  }

  .text-1 b,
  .text-1 strong {
    font-weight: bold !important; /* Ensure bold styling */
  }
}

.text-1-mobile {
  font-size: $fontMiddle;
  color: $textGray;
  text-align: justify;
  margin: 0 auto;

  b,
  strong {
    font-weight: bold !important; /* Ensure bold styling */
  }

  .text-1-mobile b,
  .text-1-mobile strong {
    font-weight: bold !important; /* Ensure bold styling */
  }
}

h3 {
  font-family: MontserratSemiBold;
  color: $bluFour;
  line-height: inherit;
  margin-bottom: 1rem;
}

.header-container {
  padding: 1rem; // Add padding for spacing
}

.contentButton {
  width: 190px;
  max-width: 280px;
  margin: 2.5rem auto;
  text-align: -webkit-center;
}

.cdlButton {
  max-width: 180px;
  background-image: linear-gradient(6deg, $bluTwo 0%, $bluThree 100%);
  color: white;
  border-radius: 41px;
  padding: 20px;
  text-align: center;
  font-family: OpenSansRegular;
  border: 0;
  cursor: pointer;
  font-size: $font19_2;

  &::selection {
    background: $bluSix;
  }
}

.bold-underlined {
  font-weight: bold;
  text-decoration: underline;
}

.intro-text {
  font-size: 20px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  color: #002e5f;
  text-align: left;
  margin-bottom: 1rem; /* Optional: Add some space between the paragraph and the lists */
}

.doubleCrossContent-card {
  flex: 1; // Allow these containers to grow and take up available space
  margin-bottom: 1rem; // Add some margin for spacing
}

.cdlButtonSupport {
  width: 270px;
  height: 72px;
  background: linear-gradient(119deg, #011930 0%, #032f5e 100%);
  box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.1);
  border-radius: 112px;

  .icon {
    margin-right: 0.5rem; // Adjust margin as needed
    font-size: 1.5rem; // Adjust font size as needed
  }
}

.list-container {
  padding: 1rem; // Add some padding for spacing
  .title-list {
    margin-bottom: 1rem; // Add some margin for spacing
    li {
      font-size: 20px;
      font-weight: bold;
      font-family: "Open Sans", sans-serif;
      color: #002e5f;
      text-align: left;
    }
  }

  .lists-content {
    display: flex;
    justify-content: space-between;

    .text-list {
      width: 740px;

      li {
        font-size: 1rem;
        color: #002e5f;
        text-align: left;
      }
    }
  }
}

.doubleCrossContent {
  display: flex;
  justify-content: space-around;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  text-align: center;
  font-family: OpenSansRegular;
  font-size: $fontNormal;

  .singleCard {
    position: relative;
    box-shadow: 0px 0px 10px #0000000d;
    border-radius: 20px;
    padding: 10px;
    background: transparent;
    width: 40%; // Set a width to keep the cards consistent
    max-width: 588px;

    &::before {
      content: "";
      position: absolute;
      top: -5px;
      left: -5px; // the width of the border
      right: -5px;
      bottom: -5px;
      background: linear-gradient(89deg, $yellowOne 0%, $azure 100%);
      border-radius: 25px; // This should be border-radius + border-width
      z-index: -1;
    }

    // Common styles for title, paragraph, and button
    .title {
      font-size: $fontMiddle;
      font-family: OpenSansBold;
      color: $bluFour;
      margin: 0 0 10px 0; // Adding some margin to separate title from paragraph
    }

    .paragraph {
      font-size: $fontNormal;
      font-family: OpenSansRegular;
      color: #5f6b7a;
      margin: 0 0 10px 0; // Adding some margin to separate paragraph from button
      opacity: 1;
    }

    .button {
      padding: 0.5rem 1rem;
      background-color: $yellowOne;
      border: none;
      border-radius: 20px;
      cursor: pointer;
      color: $bluFour;
      font-family: OpenSansBold;
      font-size: $fontNormal;
    }

    // Customize the background for first and last child
    &:first-child {
      background: white;
    }

    &:last-child {
      background: white;
    }
  }
}

/* Define media queries for different screen sizes */
@media (max-width: 768px) {
  .header-container,
  .list-container,
  .doubleCrossContent {
    flex-direction: column;
    // Adjust styles for smaller screens if necessary
  }
}
</style>
