<template>
  <div class="isLink">
    <router-link to="/" @click="setNavigator()">
      <span class="material-icons">dashboard</span>
      <span>Dashboard</span>
      <span></span>
    </router-link>
  </div>
  <div v-for="(item, key) in menu" :key="key">
    <div
      class="section-link cdlMenu"
      :class="['cdlMenu-' + key]"
      @click="ctlMobileMenuOne(key)"
      v-if="shouldDisplayMenuItem(item)"
    >
      <span class="material-icons">{{ item.icon }}</span>
      <span>{{ $t(item.label) }}</span>
      <span class="material-icons" v-if="!item.open">keyboard_arrow_down</span>
      <span class="material-icons" v-if="item.open">keyboard_arrow_up</span>
    </div>
    <div v-if="item.child">
      <div
        v-for="(levelOne, kOne) in filteredLevelOne(item.child)"
        :key="kOne"
        class="subMenu"
        :class="{ active: item.open, isLink: levelOne.url }"
      >
        <router-link
          v-if="levelOne.url && shouldDisplayMenuItem(levelOne)"
          :to="'/' + $t(levelOne.url)"
          class="mdl-navigation__link cdlMenu"
          @click="setNavigator($t(levelOne.url))"
          >{{ $t(levelOne.label) }}</router-link
        >
        <div v-else-if="shouldDisplayMenuItem(levelOne)">
          <div class="cdlMenu" @click="ctlMobileMenuTwo(key, kOne)">
            <span>{{ $t(levelOne.label) }}</span>
            <span class="material-icons" v-if="!levelOne.open && levelOne.child"
              >keyboard_arrow_down</span
            >
            <span class="material-icons" v-if="levelOne.open && levelOne.child"
              >keyboard_arrow_up</span
            >
          </div>

          <div v-if="levelOne.child">
            <div
              v-for="(levelTwo, kTwo) in filteredLevelTwo(levelOne.child)"
              :key="kTwo"
              class="subMenu"
              :class="{ active: levelOne.open, isLink: levelTwo.url }"
            >
              <router-link
                v-if="levelTwo.url && shouldDisplayMenuItem(levelTwo)"
                :to="'/' + $t(levelTwo.url)"
                class="mdl-navigation__link"
                @click="setNavigator($t(levelTwo.url))"
                >{{ $t(levelTwo.label) }}</router-link
              >
              <div v-else-if="shouldDisplayMenuItem(levelTwo)">
                <div
                  class="cdlMenu"
                  @click="ctlMobileMenuThree(key, kOne, kTwo)"
                >
                  <span>{{ $t(levelTwo.label) }}</span>
                  <span
                    class="material-icons"
                    v-if="!levelTwo.open && levelTwo.child"
                    >keyboard_arrow_down</span
                  >
                  <span
                    class="material-icons"
                    v-if="levelTwo.open && levelTwo.child"
                    >keyboard_arrow_up</span
                  >
                </div>
                <div v-if="levelTwo.child">
                  <div
                    v-for="(levelThree, kThree) in filteredLevelThree(
                      levelTwo.child
                    )"
                    :key="kThree"
                    class="subMenu"
                    :class="{ active: levelTwo.open, isLink: levelThree.url }"
                  >
                    <router-link
                      v-if="levelThree.url && shouldDisplayMenuItem(levelThree)"
                      :to="'/' + $t(levelThree.url)"
                      class="mdl-navigation__link cdlMenu"
                      @click="setNavigator($t(levelThree.url))"
                      >{{ $t(levelThree.label) }}</router-link
                    >
                    <div v-else-if="shouldDisplayMenuItem(levelThree)">
                      <div
                        class="cdlMenu"
                        @click="ctlMobileMenuFour(key, kOne, kTwo, kThree)"
                      >
                        <span>{{ $t(levelThree.label) }}</span>
                        <span
                          class="material-icons"
                          v-if="!levelThree.open && levelThree.child"
                          >keyboard_arrow_down</span
                        >
                        <span
                          class="material-icons"
                          v-if="levelThree.open && levelThree.child"
                          >keyboard_arrow_up</span
                        >
                      </div>
                      <div v-if="levelThree.child">
                        <div
                          v-for="(levelFour, kFour) in filteredLevelFour(
                            levelThree.child
                          )"
                          :key="kFour"
                          class="subMenu"
                          :class="{
                            active: levelThree.open,
                            isLink: levelFour.url,
                          }"
                        >
                          <router-link
                            v-if="
                              levelFour.url && shouldDisplayMenuItem(levelFour)
                            "
                            :to="'/' + $t(levelFour.url)"
                            class="mdl-navigation__link cdlMenu"
                            @click="setNavigator($t(levelFour.url))"
                            >{{ $t(levelFour.label) }}</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="privacyPolicy">
    <span class="elmMenu"
      ><a :href="this.linkToPrivacyPolicy" target="_blank"
        >Privacy Policy</a
      ></span
    >
  </div>
</template>

<script>
import menuJson from "@/configs/menu.json";

export default {
  name: "MenuMobile",
  props: ["text", "lang", "user"],
  data() {
    return {
      menu: menuJson,
      userLanguage: "",
    };
  },
  computed: {
    linkToPrivacyPolicy() {
      const IT_LANG = "it";
      return this.userLanguage === IT_LANG
        ? "https://www.cdlan.it/informativa-privacy-ai-clienti"
        : "https://www.cdlan.it/en/privacy-policy-for-customers";
    },
  },
  methods: {
    shouldDisplayMenuItem(item) {
      // Check if the item is 'Check Coverage' by matching its URL or label
      if (
        item.url === "nav.checkCoverageUrl" ||
        item.label === "nav.checkCoverageLabel"
      ) {
        return this.getPermissionForDirectUser(item, "verifica_copertura");
      }
      // For 'Area Partner' menu item
      if (item.permissions && item.permissions.includes("area_partner")) {
        return this.getPermissionForDirectUser(item, "area_partner");
      }
      // Display all other items
      return true;
    },
    getPermissionForDirectUser(item, permission, url = null) {
      let isPermissionCoverage = item.permissions
        ? item.permissions.includes(permission)
        : false;
      let isPageCoverage = url ? item.url == url : true;
      if (!this.user.is_partner) {
        if (isPermissionCoverage && isPageCoverage) {
          return false;
        }
      }
      return true; // Or implement your own permission logic
    },
    filteredLevelOne(children) {
      return children.filter((child) => this.shouldDisplayMenuItem(child));
    },
    filteredLevelTwo(children) {
      return children.filter((child) => this.shouldDisplayMenuItem(child));
    },
    filteredLevelThree(children) {
      return children.filter((child) => this.shouldDisplayMenuItem(child));
    },
    filteredLevelFour(children) {
      return children.filter((child) => this.shouldDisplayMenuItem(child));
    },
    ctlMobileMenuOne(key) {
      this.menu[key].open = !this.menu[key].open;
    },
    ctlMobileMenuTwo(key, kOne) {
      this.menu[key].child[kOne].open = !this.menu[key].child[kOne].open;
    },
    ctlMobileMenuThree(key, kOne, kTwo) {
      this.menu[key].child[kOne].child[kTwo].open =
        !this.menu[key].child[kOne].child[kTwo].open;
    },
    ctlMobileMenuFour(key, kOne, kTwo, kThree) {
      this.menu[key].child[kOne].child[kTwo].child[kThree].open =
        !this.menu[key].child[kOne].child[kTwo].child[kThree].open;
    },
    setNavigator() {
      this.$emit("closeMenu");
    },
  },
  watch: {
    lang: {
      handler(newValue) {
        this.userLanguage = newValue;
      },
    },
  },
};
</script>
