<script setup>
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import AbDevice from "@/components/CrossConnect/ABDevice.vue";
import SummaryBox from "@/components/CrossConnect/SummaryBox.vue";
import SummaryInfo from "@/components/CrossConnect/SummaryInfo.vue";
import ConfirmationModal from "@/components/CrossConnect/ConfirmationModal.vue";
import { computed, getCurrentInstance, onMounted, ref } from "vue";
import { useDatacenterStore } from "@/stores/DataCenterStore";
import { UpdateCart } from "@/services/UpdateCart";
import { useRouter } from "vue-router";
import WarningModal from "@/components/CrossConnect/WarningModal.vue";
import IconInfo from "@/assets/img/icon_info.png";

const dataCenterStore = useDatacenterStore();
const xcArticle = computed(() => dataCenterStore.xc_article);
const dcAddress = computed(() => dataCenterStore.dc_address);
// Access the global properties directly within setup
const { config } = getCurrentInstance().appContext;
const isMobile = ref(false);
// const isCheckboxChecked = ref(false);
const router = useRouter();
onMounted(async () => {
  isMobile.value = config.globalProperties.$IsMobile();
  window.scrollTo(0, 0); // Scroll to the top of the page
});

console.log(dataCenterStore.redundancy);
console.log("dataCenterStore.roomAName: ", dataCenterStore.roomAName);

const showModal = ref(false);
const showWaringModal = ref(false);

const errorMessage = ref("");

const editInfo = async (pageNr) => {
  dataCenterStore.pageNumber = await pageNr;
  dataCenterStore.isFromvalidation = true;
  await router.push({ name: "OrderCrossConnect" });
};
const addItemsToCart = async () => {
  errorMessage.value = ""; // Reset error message
  try {
    await dataCenterStore.postCartItem();
    if (dataCenterStore.redundancy) {
      await dataCenterStore.postCartItemR(dataCenterStore.master_id);
    }
    UpdateCart?.();
    // toggleModal(); // Open modal only if no error occurs
    // Clear the data in the store
    await dataCenterStore.clearData();
    // Programmatically navigate to the CartPage route
    await router.push({ name: "CartPageit" });
  } catch (e) {
    console.log(e);
    errorMessage.value = "An error occurred: " + e.response.data.message; // Set the error message
  }
};
const updateThisCartItem = async (itemId, redundantId) => {
  errorMessage.value = ""; // Reset error message
  try {
    await dataCenterStore.updateCartItem(itemId);
    if (dataCenterStore.redundancy) {
      if (dataCenterStore.master_id && dataCenterStore.master_id !== 0) {
        await dataCenterStore.updateCartItemR(
          redundantId,
          dataCenterStore.master_id
        );
      } else {
        await dataCenterStore.postCartItemR(itemId);
      }
    }
    UpdateCart?.();
    // toggleModal(); // Open modal only if no error occurs
    // Clear the data in the store
    await dataCenterStore.clearData();
    // Programmatically navigate to the CartPage route
    await router.push({ name: "CartPageit" });
  } catch (e) {
    console.log(e);
    errorMessage.value = "An error occurred: " + e.response.data.message; // Set the error message
  }
};
const toggleModal = () => {
  try {
    showModal.value = !showModal.value;
    errorMessage.value = ""; // Reset the error message when the modal is toggled
  } catch (e) {
    console.log(e);
  }
};
const toggleWarningModal = () => {
  try {
    showWaringModal.value = !showWaringModal.value;
    errorMessage.value = ""; // Reset the error message when the modal is toggled
  } catch (e) {
    console.log(e);
  }
};
</script>
<template>
  <div id="OrderCrossCoverage" v-if="!isMobile">
    <div class="mainWrapper">
      <div
        class="contentsForm"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <div
          class="form-container"
          style="max-width: 100%; margin: 0px 0px; width: 90%"
        >
          <MyHeaderPage
            style="margin-top: 3rem"
            :calendar="true"
            :title="$t('crossConnects.validateCrossConnectLocal')"
            :subtitle="$t('checkCoverageSummary.subtitle')"
          />
          <SummaryBox style="margin-top: 3rem">
            <template #itemDescription>
              {{ xcArticle }}
            </template>
            <template #address>
              {{ dataCenterStore.boxData.address }}
            </template>
            <template #nrc
              >{{ dataCenterStore.nrc + dataCenterStore.rnrc }}
            </template>
            <template #mrc
              >{{ dataCenterStore.mrc + dataCenterStore.rmrc }}
            </template>
            <template #min_duration>{{
              dataCenterStore.kitRelatedProduct.starter_subscription_time
            }}</template>
            <template #renewal>{{
              dataCenterStore.kitRelatedProduct.regular_subscription_time
            }}</template>
            <template #release_time>{{
              dataCenterStore.kitRelatedProduct.activation_time
            }}</template>
          </SummaryBox>
          <div class="mdl-grid section">
            <div class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet">
              <div class="this-title title-headings">
                {{ $t("crossConnects.step2Heading") }}
              </div>
              <div class="subtitle">
                {{ $t("crossConnects.carefullyReadBothData") }}
              </div>
            </div>
            <div
              class="mdl-cell mdl-cell--2-col"
              style="align-self: center; margin-top: 0"
            >
              <p class="editInfo" @click="editInfo(2)">
                <span id="go-step-2" class="material-icons"> edit </span>
              </p>
            </div>
          </div>
          <div class="mdl-grid" style="align-content: flex-start">
            <AbDevice
              style="margin-top: 1rem"
              class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone descriptive-card"
              :class="[isMobile ? 'card-mobile-validation' : '']"
              :title="'A - END'"
              :room="dataCenterStore.roomAName"
              :rack="dataCenterStore.rackAName"
              :device-port="dataCenterStore.aend.device_port"
              :patch-panel="dataCenterStore.aend.patch_panel_to_mmr"
            ></AbDevice>
            <AbDevice
              style="margin-top: 1rem"
              class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone descriptive-card"
              :class="[isMobile ? 'card-mobile-validation' : '']"
              :title="'B - END'"
              :room="dataCenterStore.roomBName"
              :rack="dataCenterStore.rackBName"
              :isle="dataCenterStore.islandName"
              :device-port="dataCenterStore.bend.device_port"
              :patch-panel="dataCenterStore.bend.patch_panel_to_mmr"
              :attachment="dataCenterStore.attachment_b_end.id"
              :attachmentName="dataCenterStore.attachment_b_end?.name"
            ></AbDevice>
          </div>
          <div class="separator"></div>
          <div class="mdl-grid section">
            <div
              class="mdl-cell mdl-cell--3-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
            >
              <div class="this-title-redundancy title-headings">
                {{ $t("crossConnects.ridondanza") }}
              </div>
            </div>
            <div class="mdl-cell mdl-cell--2-col">
              <p class="editInfo" @click="editInfo(3)" style="margin-top: 10px">
                <span id="go-step-3" class="material-icons"> edit </span>
              </p>
            </div>
          </div>
          <div class="mdl-grid">
            <div class="mdl-cell mdl-cell--12-col">
              <span style="font-family: MontserratBold">{{
                dataCenterStore.redundancy === false ? "NO" : ""
              }}</span>
            </div>
          </div>
          <div
            class="mdl-grid"
            v-if="dataCenterStore?.cartItem?.cross_connect_details?.master_id"
          >
            <span style="font-weight: bold">{{
              $t("crossConnects.isRedundancy")
            }}</span>
          </div>
          <div
            class="mdl-grid"
            style="align-content: flex-start"
            v-else-if="dataCenterStore.redundancy"
          >
            <AbDevice
              class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone descriptive-card"
              :class="[isMobile ? 'card-mobile-validation' : '']"
              :title="'A - END'"
              :room="dataCenterStore.roomANameR"
              :rack="dataCenterStore.rackANameR"
              :device-port="dataCenterStore.raend.device_port"
              :patch-panel="dataCenterStore.raend.patch_panel_to_mmr"
            ></AbDevice>
            <AbDevice
              class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone descriptive-card"
              :class="[isMobile ? 'card-mobile-validation' : '']"
              :title="'B - END'"
              :room="dataCenterStore.roomBNameR"
              :rack="dataCenterStore.rackBNameR"
              :isle="dataCenterStore.islandNameR"
              :device-port="dataCenterStore.rbend.device_port"
              :attachment="dataCenterStore.attachment_rbend.id"
              :attachmentName="dataCenterStore.attachment_rbend?.name"
            ></AbDevice>
          </div>

          <div class="separator"></div>
          <div class="mdl-grid section">
            <div class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet">
              <div class="this-title title-headings">
                {{ $t("crossConnects.additionalInfo") }}
              </div>
              <div class="subtitle">
                {{ $t("crossConnects.controlWithAttention") }}
              </div>
            </div>
            <div
              class="mdl-cell mdl-cell--1-col"
              style="align-self: center; margin-top: 0"
            >
              <p class="editInfo" @click="editInfo(4)">
                <span id="go-step-4" class="material-icons"> edit </span>
              </p>
            </div>
          </div>
          <div style="margin-left: 8px">
            <SummaryInfo />
          </div>
          <div class="separator"></div>
          <div class="mdl-grid info-section">
            <div class="mdl-cell mdl-cell--12-col info-container">
              <img :src="IconInfo" alt="Info Icon" class="info-icon" />
              <p class="info-text" v-html="$t('info-tec.info')"></p>
            </div>
          </div>
          <div class="separator"></div>
          <div class="button-container">
            <button
              class="cdlButtonGreen" id="finalSubmit"
              @click="
                dataCenterStore.cartItem?.id
                  ? updateThisCartItem(
                      dataCenterStore.cartItem.id,
                      dataCenterStore.redundantData?.id
                    )
                  : addItemsToCart()
              "
            >
              {{
                dataCenterStore.isCartItem
                  ? $t("crossConnects.updateItem")
                  : $t("crossConnects.validate")
              }}
            </button>
            <div
              class="deleteOrder"
              @click="toggleWarningModal()"
              style="margin-bottom: 20px"
            >
              {{ $t("crossConnects.cancel") }}
            </div>
            <WarningModal
              v-if="showWaringModal"
              :isOpen="showWaringModal"
              @update:isOpen="toggleWarningModal"
            />
          </div>
          <ConfirmationModal
            v-if="showModal"
            :isOpen="showModal"
            @update:isOpen="toggleModal"
          />
          <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
        </div>
      </div>
    </div>
  </div>
  <div id="OrderCrossCoverage" v-if="isMobile">
    <div class="mainWrapper">
      <div
        class="contentsForm"
        style="
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <div
          class="form-container-mobile"
          style="max-width: 100%; margin: 0px 0px; width: 90%"
        >
          <MyHeaderPage
            style="margin-top: 3rem"
            :calendar="true"
            :title="$t('crossConnects.validateCrossConnectLocal')"
            :subtitle="$t('checkCoverageSummary.subtitle')"
          />
          <SummaryBox style="margin-top: 3rem" :is-mobile="isMobile">
            <template #itemDescription>
              {{ xcArticle }}
            </template>
            <template #address>
              {{ dcAddress }}
            </template>
            <template #nrc
              >{{ dataCenterStore.nrc + dataCenterStore.rnrc }}
            </template>
            <template #mrc
              >{{ dataCenterStore.mrc + dataCenterStore.rmrc }}
            </template>
            <template #min_duration>{{
              dataCenterStore.kitRelatedProduct.starter_subscription_time
            }}</template>
            <template #renewal>{{
              dataCenterStore.kitRelatedProduct.regular_subscription_time
            }}</template>
            <template #release_time>{{
              dataCenterStore.kitRelatedProduct.activation_time
            }}</template>
          </SummaryBox>
          <div class="mdl-grid section">
            <div class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet">
              <div class="this-title title-headings">
                {{ $t("crossConnects.step2Heading") }}
              </div>
              <div class="subtitle">
                {{ $t("crossConnects.carefullyReadBothData") }}
              </div>
            </div>
            <div
              class="mdl-cell mdl-cell--2-col mdl-cell--4-col-phone"
              style="align-self: center; margin-top: 0"
            >
              <p class="editInfo" @click="editInfo(2)">
                <span id="go-step-2" class="material-icons"> edit </span>
              </p>
            </div>
            <AbDevice
              class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone descriptive-card"
              :class="[isMobile ? 'card-mobile-validation' : '']"
              :title="'A - END'"
              :room="dataCenterStore.roomAName"
              :rack="dataCenterStore.rackAName"
              :device-port="dataCenterStore.aend.device_port"
              :patch-panel="dataCenterStore.aend.patch_panel_to_mmr"
            ></AbDevice>

            <AbDevice
              class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone descriptive-card"
              :class="[isMobile ? 'card-mobile-validation' : '']"
              :title="'B - END'"
              :room="dataCenterStore.roomBName"
              :rack="dataCenterStore.rackBName"
              :isle="dataCenterStore.islandName"
              :device-port="dataCenterStore.bend.device_port"
              :patch-panel="dataCenterStore.bend.patch_panel_to_mmr"
              :attachment="dataCenterStore.attachment_b_end"
              :attachmentName="dataCenterStore.attachment_b_end.name"
            ></AbDevice>
          </div>
          <div class="separator"></div>
          <div class="mdl-grid" style="margin-left: -15px">
            <div
              class="mdl-cell mdl-cell--3-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
            >
              <div class="this-title-redundancy title-headings">
                {{ $t("crossConnects.ridondanza") }}
              </div>
              <div class="subtitle"></div>
            </div>
            <div class="mdl-cell mdl-cell--2-col mdl-cell--4-col-phone">
              <p class="editInfo" @click="editInfo(3)" style="margin-top: 10px">
                <span id="go-step-3" class="material-icons"> edit </span>
              </p>
            </div>
          </div>
          <div class="mdl-grid">
            <div class="mdl-cell mdl-cell--12-col">
              <span style="font-family: MontserratBold">{{
                dataCenterStore.redundancy === false ? "NO" : ""
              }}</span>
            </div>
          </div>
          <div
            class="mdl-grid"
            style="align-content: flex-start"
            v-if="dataCenterStore.redundancy"
          >
            <AbDevice
              class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone descriptive-card"
              :class="[isMobile ? 'card-mobile-validation' : '']"
              :title="'A - END'"
              :room="dataCenterStore.roomANameR"
              :rack="dataCenterStore.rackANameR"
              :device-port="dataCenterStore.raend.device_port"
              :patch-panel="dataCenterStore.raend.patch_panel_to_mmr"
            ></AbDevice>
            <AbDevice
              class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone descriptive-card"
              :class="[isMobile ? 'card-mobile-validation' : '']"
              :title="'B - END'"
              :room="dataCenterStore.roomBNameR"
              :rack="dataCenterStore.rackBNameR"
              :isle="dataCenterStore.islandNameR"
              :device-port="dataCenterStore.rbend.device_port"
              :attachment="dataCenterStore.attachment_rbend.id"
              :attachmentName="dataCenterStore.attachment_rbend?.name"
            ></AbDevice>
          </div>

          <div class="separator"></div>
          <div class="mdl-grid" style="margin-left: -15px">
            <div class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet">
              <div class="this-title title-headings-mobile">
                {{ $t("crossConnects.additionalInfo") }}
              </div>
              <div class="subtitle">
                {{ $t("crossConnects.controlWithAttention") }}
              </div>
            </div>
            <div
              class="mdl-cell mdl-cell--1-col mdl-cell--4-col-phone"
              style="align-self: center; margin-top: 0"
            >
              <p class="editInfo" @click="editInfo(4)">
                <span id="go-step-4" class="material-icons"> edit </span>
              </p>
            </div>
          </div>
          <br />
          <SummaryInfo />
          <div class="separator"></div>
          <!-- New Section with Grey Text -->
          <div class="mdl-grid info-section">
            <div class="mdl-cell mdl-cell--12-col info-container">
              <img :src="IconInfo" alt="Info Icon" class="info-icon-mobile" />
              <p class="info-text" v-html="$t('info-tec.info')"></p>
            </div>
          </div>
          <div class="separator"></div>
          <div class="button-container">
            <button class="cdlButtonGreen" id="addToCart" @click="addItemsToCart">
              {{
                dataCenterStore.isCartItem
                  ? $t("crossConnects.updateItem")
                  : $t("crossConnects.validate")
              }}
            </button>
            <br />
            <div class="deleteOrder" @click="toggleWarningModal()">
              {{ $t("crossConnects.cancel") }}
            </div>
            <WarningModal
              v-if="showWaringModal"
              :isOpen="showWaringModal"
              @update:isOpen="toggleWarningModal"
            />
          </div>
          <ConfirmationModal
            v-if="showModal"
            :isOpen="showModal"
            @update:isOpen="toggleModal"
          />
          <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";

.section {
  margin-top: 4rem;
}
.info-section {
  display: flex;
  align-items: center;
}

.info-container {
  display: flex;
  align-items: flex-start;
}

.info-icon {
  margin-right: 12px;
  flex-shrink: 0;
  width: 2%;
}
.info-icon-mobile {
  margin-right: 12px;
  flex-shrink: 0;
}

.info-text {
  color: #8895a7;
  line-height: 1.5;
}

.card-validation-mobile {
  overflow: hidden;
  min-width: 22rem;
  width: 30.6rem; // 490px
  height: auto; // 214px
  border: 0.18rem $graySeven; // 3px
  border-radius: 0.6rem; // 10px
  background: $grayTwo;
  display: flex; // Set the display to flex
  flex-direction: column; // Stack content vertically
  padding: 0 1rem 0 3rem; // Set horizontal padding to ensure content doesn't touch the edges
  color: #002e5f;
  max-width: 100%; // This ensures that the card does not overflow its container */
  max-height: 100%;
  padding: 2.5rem; // adjust padding as necessary
  margin: 4rem 5rem;

  .additional-content {
    flex-grow: 1; // This will allow this container to grow and extend the card downwards
  }

  .divider {
    margin: 2rem 0; // Adjusted margin to create spacing around the divider
    min-height: 0.2rem;
    background-color: $graySix;
    margin-right: -1rem;
    width: 401px;
    max-width: 401px; // Extend to the right edge of the card
  }
}

.card-validation-grid {
  display: grid;
  grid-template-columns: auto auto; /* Adjust the column sizes as needed */
  row-gap: 20px; /* Space between rows and columns */
  justify-items: flex-start;

  .grid-title {
    grid-column: 1 / -1; /* Span across all columns */
    text-align: center;
    font-family: MontserratMedium;

    /* Additional styles for the title */
  }

  .grid-label {
    /* Styles for labels */
    text-align: right;
    color: #002e5f;
    font-family: MontserratMedium;
    font-size: 16px;
    font-weight: normal;
  }

  .grid-value {
    /* Styles for values */
    text-align: left;
  }
}

.editInfo {
  background-color: $grayFive;
  color: $blu;
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 0.875rem;
  text-align: center;
  margin-top: 30px;

  &:hover {
    background-color: white;
  }

  &.active {
    .material-icons {
      color: $green;
    }
  }
}

.overflow-hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
}

.error-message {
  color: red;
  font-size: 12px; // Adjust font size as needed
  margin-top: 2px; // Adjust margin as needed
  text-align: center;
}

.clause-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px; // Adjust as needed to provide spacing above the button
  font-size: 12px;
}

.clause-container input[type="checkbox"] {
  margin-right: 10px;
}

.clause-container label {
  font-size: 12px; // Adjust based on your design preference
  color: #002e5f; // This is a muted gray; adjust as desired
  font-family: OpenSansSemiBold;
}

.info-header {
  padding: 10px 0;
  font-weight: bold;
  font-family: Monte;
}

.info-row {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.info-item {
  display: flex;
  align-items: baseline;
  margin-right: 3.75rem;
}

.label {
  margin-right: 10px;
  font-weight: bold;
}

.value {
  font-family: OpenSansBold;
  font-size: 18px;
}

.descriptive-text-container {
  display: flex;
  max-width: 24rem;
}

.descriptive-card {
  width: 32rem !important;
  text-align: left !important;
  margin-left: 0 !important;
}

.descriptive-text {
  float: right;
  flex-grow: 2;
}

.contentsForm {
  .title-headings {
    font-family: "MontserratMedium" !important;
    font-size: 36px !important;
  }
  .title-headings-mobile {
    font-family: "MontserratMedium" !important;
    font-size: 28px !important;
  }

  margin-bottom: 3rem;
  margin-top: 0rem !important;
}

.sectionMargin {
  margin-bottom: 0;
}

.flex-row.detail-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .sectionTitle {
    display: flex;
    align-items: center; // to ensure the icon and text are vertically aligned
  }

  .value {
    margin-left: 1rem; // this pushes the value to the rightmost side of its container
  }

  .card-value {
    float: right;
  }

  .align-right {
    float: right;
  }

  div {
    overflow: auto;
  }
}

.label {
  color: #002e5f;
  font-family: MontserratMedium;
  font-size: 18px;
  font-weight: normal;
}
</style>
